const config = require("./config.json");
const { get, create, update, remove } = require("./common").default;
const { BlobServiceClient } = require("@azure/storage-blob");

const projectsUrl = config.apis.base.url + "projects/";

exports.getProjectById = async (id, fullProjectsData) => {
  let query = "";
  if (fullProjectsData !== undefined && fullProjectsData === false) {
    query = "?fullProjectsData=0";
  }
  return await get(`${projectsUrl}${id}${query}`);
};

exports.getProjects = async (archived) => {
  let qs = "";
  if (archived !== undefined) {
    if (archived) {
      qs = "?archived=1";
    } else {
      qs = "?archived=0";
    }
  }

  return await get(projectsUrl + qs);
  // Sample data
  // const projects = [
  //     { "id": 1, "name": "Project One", "client_id": 2, "action": "Action required", "current_stage": "Awaiting feedback", "image": "assets/images/image-gallery/10.jpg", "created_on": "2021-12-24T13:10:15.000Z", "updated_on": "2021-12-24T13:10:15.000Z" },
  //     { "id": 2, "name": "Project Two", "client_id": 1, "action": "Delivered", "current_stage": "Delivered", "image": "assets/images/image-gallery/11.jpg", "created_on": "2021-12-24T13:10:15.000Z", "updated_on": "2021-12-24T13:10:15.000Z" },
  //     { "id": 1, "name": "Project One", "reference_code": null, "client_id": 2, "image": "assets/images/image-gallery/10.jpg", "brief_document_link": null, "start_date": null, "days_allowed_for_client_feedback": null, "feedback_reminder_delay": null, "email_subject_line_default": null, "workflow_id": 1, "main_contact_id": null, "deleted": 0, "created_on": "2021-12-24T13:10:15.000Z", "updated_on": "2022-01-05T10:17:11.000Z"},
  //     { "id": 2, "name": "Project Two", "reference_code": null, "client_id": 1, "image": "assets/images/image-gallery/11.jpg", "brief_document_link": null, "start_date": null, "days_allowed_for_client_feedback": null, "feedback_reminder_delay": null, "email_subject_line_default": null, "workflow_id": null, "main_contact_id": null, "deleted": 0, "created_on": "2021-12-24T13:10:15.000Z", "updated_on": "2021-12-24T13:10:15.000Z" },    // ]
};

/**
 * Return only the projects whose name matches the @param filter (case insensitive)
 * @param {object[]} projects Array of Projects
 * @param {string} filter String to search in the project name
 * @returns Array of Projects
 */
exports.filterProjects = (projects, filter) => {
  if (filter) {
    return projects.filter((project) =>
      project.name.toLowerCase().includes(filter.toLowerCase())
    );
  }

  return projects;
};

exports.addProject = async (project) => {
  if (!project.client_id || !project.workflow_id) {
    throw new Error(
      "Client ID and Workflow ID must be present to create a project"
    );
  }
  return await create(projectsUrl, project);
};

/**
 * Update the given project object.
 * An id must be provided and @param project.id takes precedence over @param projectId
 * @param {object} project The object to update. The id can be omitted if specified in @param projectId, but is required otherwise
 * @param {number} projectId Optional. Needed only if @param project does not have an id property
 * @returns
 */
exports.updateProject = async (project, projectId) => {
  if (!project.id || project.id < 1) {
    if (!projectId || projectId < 1) {
      throw new Error("No valid project ID provided");
    }
    project.id = projectId;
  }
  delete project.is_survey_sent; // This field is not allowed to be updated from here
  return await update(projectsUrl + project.id, project);
};

exports.archiveProjectById = async (id, shouldArchive) => {
  return await update(projectsUrl + id + "/archive", {
    archive: shouldArchive,
  });
};
exports.cancelProjectById = async (id, shouldCancel) => {
  return await update(projectsUrl + id + "/cancel", {
    cancel: shouldCancel,
  });
};

exports.deleteProject = async (project) => {
  return this.deleteProjectById(project.id);
};

exports.deleteProjectById = async (id) => {
  return await remove(projectsUrl + id);
};

/**
 * Send a Preview email (or Reminder) to the Client from the Agency Portal
 * @param {number} project_id
 * @param {string} revisionId Guid
 * @param {string} emailText
 * @param {string} reviewLink
 * @param {boolean} isFirstEmail
 * @param {boolean} isReminder
 * @param {boolean} updateStatus Should we advance the revision status to 'Awaiting feedback'?
 * @returns 'Email sent' message
 */
exports.sendRevisionPreview = async (
  project_id,
  revisionId,
  emailText,
  reviewLink,
  isFirstEmail,
  isReminder,
  updateStatus
) => {
  const url = `${config.apis.projects.url}/${project_id}/timeline/revision/${revisionId}/send-preview`;
  return await update(url, {
    emailText,
    reviewLink,
    isFirstEmail,
    isReminder,
    updateStatus,
  });
};

/**
 * Sends a message from the Client Portal
 * @param {number} project_id
 * @param {string} revisionId Guid
 * @param {string} emailText
 * @param {boolean} isFirstEmail
 * @returns 'Email sent' message
 */
exports.sendClientRevisionMessage = async (
  project_id,
  revisionId,
  emailText,
  isFirstEmail
) => {
  const url = `${config.apis.projects.url}/${project_id}/timeline/revision/${revisionId}/send-message`;
  return await update(url, { emailText, isFirstEmail });
};

/**
 * Sends a message from the Agency Portal for Stages
 * When the stage does not have revisions
 * @param {number} project_id
 * @param {string} stageId Guid
 * @param {string} emailText
 * @param {boolean} isFirstEmail
 * @returns 'Email sent' message
 */
exports.sendStageMessage = async (
  project_id,
  stageId,
  emailText,
  isFirstEmail
) => {
  const url = `${config.apis.projects.url}/${project_id}/timeline/stages/${stageId}/send-message`;
  return await update(url, { emailText, isFirstEmail });
};

/* Deliverables */

exports.getProjectDeliverables = async (id) => {
  return await get(projectsUrl + id + "/deliverables");
};

exports.saveProjectDeliverable = async (projectId, deliverable) => {
  if (deliverable.id) {
    return await update(
      projectsUrl + projectId + `/deliverables/${deliverable.id}`,
      deliverable
    );
  } else {
    return await create(projectsUrl + projectId + "/deliverables", deliverable);
  }
};

exports.sendProjectDeliverable = async (
  projectId,
  deliverable,
  emailTemplateId
) => {
  return await create(
    projectsUrl + projectId + `/deliverables/${deliverable.id}/send`,
    { emailTemplate: emailTemplateId }
  );
};

/* Stats */

/**
 * Get tracklink for a project revision
 * @param {int} projectId
 * @param {guid} revision_id
 * @returns TrackLink object
 */
exports.getTracklinkForProjectRevision = async (projectId, revision_id) => {
  return await get(
    `${projectsUrl}${projectId}/revisions/${revision_id}/tracklinks`
  );
};
/**
 * Get average review response time for a project revisions
 * @param {int} projectId
 */
exports.getProjectAverageReviewResponseTime = async (projectId) => {
  return await get(`${projectsUrl}${projectId}/averageReviewResponseTime`);
};

/**
 * Get the last clicked tracklink(review link) for each project in a list of projects.
 * It does not matter if the link was from an older revision, we show the last interaction.
 * @param {int[]} projectIds Array of Project IDs
 * @returns Array of Tracklink objects
 */
exports.getLastClickedTracklinkForProjectsList = async (projectIds) => {
  if (!projectIds.length === 0) return [];
  return await get(
    `${projectsUrl}${projectIds.join(",")}/tracklinks/last-clicked`
  );
};

exports.getProjectsStats = async () => {
  const projects_stats = await get(config.apis.base.url + "reports/projects");
  // Sample data
  // const projects_stats = [
  //   {"id": 1,"project_name": "Project Test Basic","reference_code": "P1","client_id": 2,"client_name": "ABX Ltd.", "status": "awaiting_feedback", "current_stage": "Artwork"},
  //   {"id": 4,"project_name": "Test Update Min","reference_code": "P4","client_id": 2,"client_name": "ABX Ltd.", "status": "in_progress", "current_stage": "Storyboarding"},
  //   {"id": 5,"project_name": "Test Update 2","reference_code": "P5","client_id": 1,"client_name": "DEX corp.", "status": "in_progress", "current_stage": "Storyboarding"},
  //   {"id": 18,"project_name": "wf timl","reference_code": "PWT","start_date": "1970-01-01","client_id": 2,"client_name": "ABX Ltd.", "status": "completed"} ]
  return projects_stats;
};
exports.getUserBasedProjectsStats = async () => {
  const projects_stats = await get(
    config.apis.base.url + "reports/user-projects"
  );
  // Sample data
  // const projects_stats = [
  //   {"id": 1,"project_name": "Project Test Basic","reference_code": "P1","client_id": 2,"client_name": "ABX Ltd.", "status": "awaiting_feedback", "current_stage": "Artwork"},
  // ]
  return projects_stats;
};

exports.getProjectStats = async (projectId) => {
  const project_stats = await get(
    config.apis.base.url + "reports/projects/" + projectId
  );
  // Sample data
  // const projects_stats = {"id": 1,"project_name": "Project Test Basic","reference_code": "P1","client_id": 2,"client_name": "ABX Ltd.", "status": "awaiting_feedback", "current_stage": "Artwork"}
  return project_stats;
};

exports.updateProjectStats = async (projectId) => {
  return await update(config.apis.base.url + "reports/projects/" + projectId);
  // return projects_stats;
};

exports.undoLastTimelineAction = async (project_id, reason) => {
  const url = `${config.apis.projects.url}/${project_id}/timeline/actions/undo-last`;
  return await update(url, { reason });
};

exports.getProjectTimelineEvents = async (project_id) => {
  const url = `${config.apis.projects.url}/${project_id}/timeline/events`;
  return await get(url);
};
exports.getProjectsEvents = async () => {
  const url = `${config.apis.projects.url}/events`;
  return await get(url);
};
exports.undoLastNonLinearTimelineAction = async (
  project_id,
  stageId,
  reason
) => {
  const url = `${config.apis.projects.url}/${project_id}/timeline/stages/${stageId}/actions/undo-last`;
  return await update(url, { reason });
};
exports.startStageNonLinearTimelineAction = async (project_id, stageId) => {
  const url = `${config.apis.projects.url}/${project_id}/timeline/stages/${stageId}/actions/start`;
  return await update(url);
};

exports.getDeliverableFiles = async (projectId, deliverableId) => {
  return await get(
    `${projectsUrl}${projectId}/deliverables/${deliverableId}/files`
  );
};

exports.deleteDeliverable = async (projectId, deliverableId) => {
  return await remove(
    `${projectsUrl}${projectId}/deliverables/${deliverableId}`
  );
};

exports.deleteDeliverableFile = async (projectId, deliverableId, filename) => {
  return await remove(
    `${projectsUrl}${projectId}/deliverables/${deliverableId}/files?filename=${filename}`
  );
};

exports.addDeliverableFile = async (
  projectId,
  deliverableId,
  file,
  isThumbnail = false,
  onProgress
) => {
  const blobName = file.name;
  const blobPath = `projects/${projectId}/deliverables/${deliverableId}/${
    isThumbnail ? "thumbnails/" : ""
  }${blobName}`;

  const sasUrl = await get(
    `${projectsUrl}${projectId}/deliverables/${deliverableId}/files/sas-token`,
    {
      params: {
        filename: isThumbnail ? `thumbnails/${blobName}` : blobName,
        filesize: file.size,
      },
    }
  );

  const blobServiceClient = new BlobServiceClient(sasUrl);
  const containerClient = blobServiceClient.getContainerClient("");
  const blockBlobClient = containerClient.getBlockBlobClient(blobPath);

  return blockBlobClient
    .uploadData(file, {
      onProgress,
    })
    .then(() => {
      if (!isThumbnail)
        update(
          `${projectsUrl}${projectId}/deliverables/${deliverableId}/files?filename=${blobName}&content_type=${file.type}&filesize=${file.size}`
        );
    });
};

exports.getRevisionFiles = async (projectId, revisionId) => {
  return await get(`${projectsUrl}${projectId}/revisions/${revisionId}/files`);
};

exports.deleteRevisionFile = async (projectId, revisionId, filename) => {
  return await remove(
    `${projectsUrl}${projectId}/revisions/${revisionId}/files?filename=${filename}`
  );
};

exports.addRevisionFile = async (
  projectId,
  revisionId,
  file,
  isThumbnail = false,
  onProgress
) => {
  const blobName = file.name;
  const blobPath = `projects/${projectId}/revisions/${revisionId}/${
    isThumbnail ? "thumbnails/" : ""
  }${blobName}`;

  const sasUrl = await get(
    `${projectsUrl}${projectId}/revisions/${revisionId}/files/sas-token`,
    {
      params: {
        filename: isThumbnail ? `thumbnails/${blobName}` : blobName,
        filesize: file.size,
      },
    }
  );

  const blobServiceClient = new BlobServiceClient(sasUrl);
  const containerClient = blobServiceClient.getContainerClient("");
  const blockBlobClient = containerClient.getBlockBlobClient(blobPath);

  return blockBlobClient
    .uploadData(file, {
      onProgress,
    })
    .then(() => {
      if (!isThumbnail)
        update(
          `${projectsUrl}${projectId}/revisions/${revisionId}/files?filename=${blobName}&content_type=${file.type}&filesize=${file.size}`
        );
    });
};
