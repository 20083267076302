<template>
  <div class="card">
    <div v-if="isEditing" class="mb-4 d-flex align-items-center">
      <label class="mb-0" for="ddlTemplates"
        >Choose a Deliverable Template</label
      >
      <div class="input-group-prepend ml-2">
        <button
          id="ddlTemplates"
          class="btn btn-outline-secondary dropdown-toggle"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          :disabled="isArchived"
        >
          {{ emailTemplate.title }}
        </button>
        <div class="dropdown-menu">
          <a
            v-for="(template, index) in emailTemplates"
            :key="template.title"
            class="dropdown-item"
            @click="chosenTemplateIndex = index"
            >{{ template.title }}</a
          >
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <template v-if="isEditing">
        <div class="col col-1">
          <button
            class="btn btn-danger mr-3"
            @click="isConfirmingDelete = true"
            :disabled="isSubscriptionReadOnly()"
          >
            <i class="icon-trash"></i>
          </button>
        </div>
        <div class="col col-11">
          <div class="type-switch-group btn-group btn-group-sm mb-3">
            <button
              type="button"
              class="btn"
              :class="type === 'files' ? 'btn-primary' : 'btn-default'"
              :style="
                type === 'files' &&
                'background-color: #0069d9; border-color: #0062cc'
              "
              @click="setType('files')"
            >
              Upload
            </button>
            <!-- `style` had to be used for the button's color because the bootstrap `btn-primary` class was overwritten in some components to be like the colors of `btn-info` -->
            <button
              type="button"
              class="btn"
              :class="type === 'link' ? 'btn-primary' : 'btn-default'"
              :style="
                type === 'link' &&
                'background-color: #0069d9; border-color: #0062cc'
              "
              @click="setType('link')"
            >
              Link
            </button>
          </div>

          <div class="form-group">
            <input
              type="text"
              class="form-control"
              aria-label="name"
              aria-describedby="basic-addon1"
              maxlength="255"
              placeholder="Deliverable name"
              v-model="deliverable.title"
            />
          </div>
          <div v-if="type === 'link'" class="form-group">
            <input
              type="text"
              class="form-control"
              aria-label="link"
              aria-describedby="basic-addon1"
              maxlength="255"
              placeholder="Deliverable link"
              v-model="deliverable.link"
            />
          </div>
          <div v-else-if="type === 'files'" class="form-group">
            <FileDrop
              :files="this.deliverable.files"
              :field="{ name: `deliverable-${index}-files`, multiple: true }"
              @added="addedFiles"
              @remove="removeFile"
            />
          </div>
          <textarea
            class="form-control"
            rows="5"
            v-model="deliverable.body"
            placeholder="Additional notes for this deliverable"
          />
          <h6 class="small text-black-50 mt-3">
            <span v-if="deliverable.last_save_time"
              ><i class="fa fa-save" style="color: rgb(23, 194, 215)"></i> Saved
              on: {{ getDateTime(deliverable.last_save_time) }} by
              {{ deliverable.last_save_user_name }}</span
            >
          </h6>
          <h6 class="small text-black-50 mt-2">
            <span v-if="deliverable.last_send_time"
              ><i class="fa fa-send-o" style="color: rgb(23, 194, 215)"></i>
              Sent on: {{ getDateTime(deliverable.last_send_time) }} by
              {{ deliverable.last_send_user_name }}</span
            >
          </h6>
        </div>
      </template>
      <template v-if="isViewing">
        <div class="col col-1">
          <button
            class="btn btn-danger mr-3"
            @click="isConfirmingDelete = true"
            :disabled="isSubscriptionReadOnly() || isArchived"
          >
            <i class="icon-trash"></i>
          </button>
        </div>
        <div class="col col-11">
          <div class="d-flex justify-content-between align-items-center">
            <h4 style="color: #17c2d7" class="font-weight-bold">
              {{ deliverable.title }}
            </h4>
            <button
              v-if="canEdit"
              @click="mode = 'edit'"
              class="btn btn-default d-flex align-items-center border-0"
              style="color: #747474"
              :disabled="isSubscriptionReadOnly() || isArchived"
            >
              <i class="fa fa-pencil"></i>
              <span class="ml-2">Edit</span>
            </button>
          </div>
          <div v-if="type === 'link'" class="d-flex mt-4 mb-3">
            <img
              src="/assets/images/web-address-icon.png"
              width="57"
              height="57"
            />
            <div class="d-flex flex-column flex-1 ml-4">
              <div class="d-flex align-items-end w-100">
                <div class="flex-1">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong>Link</strong>
                    <button
                      @click="copyLink"
                      class="btn btn-default d-flex align-items-center border-0"
                    >
                      <i class="fa fa-link"></i>
                      <span class="ml-2">Copy link</span>
                    </button>
                  </div>
                  <input
                    class="form-control mt-1 w-100"
                    type="text"
                    :readonly="true"
                    :value="cleanUrl(deliverable.link)"
                  />
                </div>
                <a
                  :href="cleanUrl(deliverable.link)"
                  target="_blank"
                  class="ml-4 btn btn-primary btn-round"
                  style="color: white;background-color: #0069d9; border-color: #0062cc'"
                  >View link</a
                >
              </div>
              <div v-if="deliverable.body" class="mt-3">
                <strong>Notes</strong>
                <p class="mt-1" v-html="deliverable.body"></p>
              </div>
            </div>
          </div>
          <div
            v-if="type === 'files'"
            class="mb-3 mt-4"
            style="
              display: grid;
              grid-template-columns: repeat(5, minmax(min-content, auto));
              row-gap: 1rem;
              column-gap: 2rem;
            "
          >
            <div
              v-for="(file, i) in deliverable.files"
              :key="file.name"
              style="display: contents"
            >
              <div
                style="width: 147px; height: 81px"
                class="d-flex justify-content-center align-items-center"
              >
                <i
                  v-if="fileTypeIcon(file.type, null)"
                  :class="`fa ${fileTypeIcon(file.type)} fa-2x`"
                />
                <img
                  v-else-if="file.thumbnail"
                  :src="file.thumbnail"
                  :alt="file.name"
                  class="mr-auto"
                  style="object-fit: cover; width: 100%; height: 100%"
                />
                <i v-else class="fa fa-file-o fa-2x" />
              </div>
              <div style="width: 240px">
                <p
                  class="font-weight-bold"
                  :class="{ invisible: i !== 0 }"
                  style="color: #5e5e5e"
                >
                  File name
                </p>
                <p
                  class="mb-0 text-muted"
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  :title="file.name"
                >
                  {{ file.name }}
                </p>
              </div>
              <div style="width: 90px">
                <p
                  class="font-weight-bold"
                  :class="{ invisible: i !== 0 }"
                  style="color: #5e5e5e"
                >
                  Size
                </p>
                <p class="mb-0 text-muted">{{ humanizeBytes(file.size) }}</p>
              </div>
              <div style="width: 90px">
                <p
                  class="font-weight-bold"
                  :class="{ invisible: i !== 0 }"
                  style="color: #5e5e5e"
                >
                  Date
                </p>
                <p class="mb-0 text-muted">
                  {{
                    toWorkspaceDateTimeFormat(
                      getLuxonDateTime(file.created_on, false)
                    )
                  }}
                </p>
              </div>

              <div class="ml-auto" style="width: 125px">
                <p class="font-weight-bold mb-2 invisible">Download</p>
                <a
                  :href="file.url"
                  target="_blank"
                  class="btn btn-primary btn-round"
                  style="background-color: #0069d9; border-color: #0062cc'"
                  >Download</a
                >
              </div>
            </div>
            <template v-if="deliverable.body">
              <div></div>
              <div>
                <strong>Notes</strong>
                <p class="mt-1" v-html="deliverable.body"></p>
              </div>
            </template>
          </div>
          <h6 class="small text-black-50 mt-2">
            <span v-if="deliverable.last_save_time"
              ><i class="fa fa-save" style="color: rgb(23, 194, 215)"></i> Saved
              on: {{ getDateTime(deliverable.last_save_time) }} by
              {{ deliverable.last_save_user_name }}</span
            >
          </h6>
          <h6 class="small text-black-50 mt-2">
            <span v-if="deliverable.last_send_time"
              ><i class="fa fa-send-o" style="color: rgb(23, 194, 215)"></i>
              Sent on: {{ getDateTime(deliverable.last_send_time) }} by
              {{ deliverable.last_send_user_name }}</span
            >
          </h6>
        </div>
      </template>
    </div>
    <div class="row clearfix mt-2" v-if="isEditing && $auth.user.isAgent">
      <div class="col-12">
        <div class="btn-group float-right" role="group">
          <button
            type="button"
            class="btn btn-info pr-0"
            @click="$emit('preview', emailTemplateInClientLanguage)"
          >
            Preview &amp; Send
          </button>
          <button
            type="button"
            class="btn btn-info dropdown-toggle"
            data-toggle="dropdown"
          ></button>
          <div class="dropdown-menu">
            <a class="dropdown-item" @click="sendNow">Save and send</a>
          </div>
        </div>

        <button
          v-if="isDirty"
          class="btn btn-info float-right mr-2"
          @click="save"
        >
          Save deliverable
        </button>
      </div>
    </div>
    <div class="separator-linethrough"></div>
    <ActionConfirmModal
      v-if="isConfirmingDelete"
      @close="isConfirmingDelete = false"
      @submit="
        () => {
          eventBus.$emit(channels.deleteDeliverable, index);
          isConfirmingDelete = false;
        }
      "
    />
  </div>
</template>

<script>
import xbytes from "xbytes";
import timeMixin from "../../mixins/time";
import FileDrop from "@/components/FileDrop.vue";
import eventBus, { channels } from "@/eventBus";
import {
  makeThumbnailFromImageFile,
  makeThumbnailFromVideoFile,
} from "@/utils/thumbnail";
import {
  addDeliverableFile,
  deleteDeliverableFile,
  saveProjectDeliverable,
} from "@/apis/projects";
import { fileTypeIcon } from "@/utils/icon";
import ActionConfirmModal from "@/components/ui/Modals/ActionConfirmModal.vue";

export default {
  name: "ProjectDeliverable",
  components: { ActionConfirmModal, FileDrop },
  mixins: [timeMixin],
  props: {
    index: Number,
    deliverable: Object,
    isDirty: Boolean,
    project: Object,
  },
  data() {
    let mode = !this.deliverable.title ? "edit" : "preview";

    return {
      mode,
      chosenTemplateIndex: 0,
      isConfirmingDelete: false,
    };
  },
  computed: {
    isViewing() {
      return !this.canEdit || this.mode === "preview";
    },
    isEditing() {
      return this.canEdit && this.mode === "edit";
    },
    canEdit() {
      return this.$auth.user.isAgent;
    },
    channels() {
      return channels;
    },
    eventBus() {
      return eventBus;
    },
    type() {
      if (this?.deliverable?.type) return this.deliverable.type;

      if (this.deliverable?.files?.length) return "files";

      return "link";
    },
    isArchived() {
      return Boolean(this.project.archived);
    },
    allEmailTemplates() {
      if (this.$auth.user.isAgent) {
        return (this.$store.getters.emailTemplates || []).filter(
          (template) => template.type === "deliverable"
        );
      } else {
        return [];
      }
    },
    emailTemplates() {
      return this.allEmailTemplates.filter((t) => !t.parent_id);
    },
    emailTemplate() {
      return this.emailTemplates[this.chosenTemplateIndex ?? 0];
    },
    emailTemplateInClientLanguage() {
      const clientLanguage = this.client?.language ?? "en";
      const chosenEmailTemplateId = this.emailTemplate.id;
      const templateVariants = this.allEmailTemplates.filter(
        (t) =>
          t.id === chosenEmailTemplateId ||
          t.parent_id === chosenEmailTemplateId
      );

      let template = templateVariants.find(
        (t) => t.language === clientLanguage
      );

      if (!template)
        // template in client language was not found, fall back on default language
        template = templateVariants.find((t) => t.language === "en");

      if (!template)
        // default to the parent template
        return this.emailTemplate;

      return template;
    },
  },
  watch: {
    deliverable: {
      deep: true,
      handler: function () {
        eventBus.$emit(
          channels.updateDeliverable,
          this.index,
          this.deliverable
        );
      },
    },
  },
  methods: {
    sendNow() {
      if (this.type === "link" && !this.deliverable.link) {
        this.notifyError("Deliverable link is required");
        return;
      }

      this.$emit("send", this.emailTemplateInClientLanguage);
      this.save();
    },
    fileTypeIcon,
    save() {
      if (this.type === "link" && !this.deliverable.link) {
        this.notifyError("Deliverable link is required");
        return;
      }

      eventBus.$emit(channels.saveDeliverable, this.index);
    },
    setMode(mode) {
      this.mode = mode;
    },
    humanizeBytes(bytes) {
      if (!bytes) return "";

      return xbytes(bytes);
    },
    copyLink() {
      const link = this.cleanUrl(this.deliverable.link);
      if (link) {
        navigator.clipboard.writeText(link);
      }
    },
    removeFile(index) {
      const files = [...this.deliverable.files];
      const filename = files[index]?.name;

      files.splice(index, 1);

      deleteDeliverableFile(this.project.id, this.deliverable.id, filename);

      eventBus.$emit(channels.updateDeliverable, {
        index: this.index,
        data: {
          ...this.deliverable,
          files: files,
        },
      });
    },
    async addedFiles(files) {
      if (!files?.length) return;

      // filter out disallowed file extensions
      files = files.filter((file) => {
        if (
          !file.type ||
          file.type.match("x-sh") ||
          file.type.match("x-msdownload")
        ) {
          alert(
            file.type
              ? `${file.name ?? "file"} type ${file.type} is not allowed`
              : `${file.name ?? "file"} has no type`
          );

          return false;
        }

        return true;
      });

      // filter out existing files
      files = files.filter((file, i) => {
        const existingFileIndex = this.deliverable.files.findIndex(
          (f) => f.name === file.name
        );
        if (existingFileIndex >= 0) {
          if (
            confirm(`${file.name} already exists. Do you want to overwrite?`)
          ) {
            files[i].toReplace = existingFileIndex;
            return true;
          }
          return false;
        }

        return true;
      });

      if (!files?.length) return;

      if (!this.deliverable?.id) {
        const deliverable = { ...this.deliverable };
        delete deliverable.type;
        delete deliverable.files;
        await saveProjectDeliverable(this.project.id, deliverable).then(
          (res) => {
            this.deliverable.id = res.id;
            eventBus.$emit(channels.updateDeliverable, {
              index: this.index,
              data: {
                ...this.deliverable,
                ...res,
              },
            });
          }
        );
      }

      eventBus.$emit(channels.updateDeliverable, {
        index: this.index,
        data: {
          ...this.deliverable,
          files: [...this.deliverable.files, ...files],
        },
      });

      this.$nextTick(() => {
        files.map(async (file) => {
          file.isUploading = true;
          let thumbnail;
          if (file.type.match("image")) {
            thumbnail = await makeThumbnailFromImageFile(file);
            file.thumbnail = URL.createObjectURL(thumbnail);
          } else if (
            file.type.match("video") &&
            file.type !== "video/quicktime"
          ) {
            thumbnail = await makeThumbnailFromVideoFile(file);
            file.thumbnail = URL.createObjectURL(thumbnail);
          }

          const fileIndex = this.deliverable.files.findIndex(
            (f) => f.name === file.name
          );

          const deliverableFiles = [...this.deliverable.files];
          deliverableFiles[fileIndex].progress = 0;
          deliverableFiles[fileIndex].isUploading = true;

          eventBus.$emit(channels.updateDeliverable, {
            index: this.index,
            data: {
              ...this.deliverable,
              files: deliverableFiles,
            },
          });

          addDeliverableFile(
            this.project.id,
            this.deliverable.id,
            file,
            false,
            (progress) => {
              const fileIndex = this.deliverable.files.findIndex(
                (f) => f.name === file.name
              );
              if (fileIndex === -1) return;

              const deliverableFiles = [...this.deliverable.files];
              deliverableFiles[fileIndex].progress =
                progress.loadedBytes / file.size;

              eventBus.$emit(channels.updateDeliverable, {
                index: this.index,
                data: {
                  ...this.deliverable,
                  files: deliverableFiles,
                },
              });
            }
          )
            .then(() => {
              const fileIndex = this.deliverable.files.findLastIndex(
                (f) => f.name === file.name
              );
              if (fileIndex < 0) return;

              const deliverableFiles = [...this.deliverable.files];
              deliverableFiles[fileIndex].isUploading = false;
              if (file.toReplace >= 0)
                deliverableFiles.splice(file.toReplace, 1);

              eventBus.$emit(channels.updateDeliverable, {
                index: this.index,
                data: {
                  ...this.deliverable,
                  files: deliverableFiles,
                },
              });
            })
            .catch((error) => {
              this.notifyError(
                error,
                `Error uploading ${file.name ?? "deliverable file"}`
              );

              const fileIndex = this.deliverable.files.findIndex(
                (f) => f.name === file.name
              );
              if (fileIndex < 0) return;

              const deliverableFiles = [...this.deliverable.files];
              deliverableFiles.splice(fileIndex, 1);

              eventBus.$emit(channels.updateDeliverable, {
                index: this.index,
                data: {
                  ...this.deliverable,
                  files: deliverableFiles,
                },
              });
            });

          if (thumbnail) {
            addDeliverableFile(
              this.project.id,
              this.deliverable.id,
              thumbnail,
              true
            );
          }
        });
      });
    },
    setType(newType) {
      eventBus.$emit(channels.updateDeliverable, {
        index: this.index,
        data: {
          ...this.deliverable,
          type: newType,
        },
      });
    },
    cleanUrl(url) {
      if (!url) return null;
      url = url.trim();
      if (url.length === 0) return null;
      if (url.startsWith("http://") || url.startsWith("https://")) return url;
      return "https://" + url;
    },
    getDateTime(date) {
      return this.toWorkspaceDateTimeFormat(this.getLuxonDateTime(date, true));
    },
  },
};
</script>

<style scoped>
.separator-linethrough::after {
  border-color: #dde4ea !important;
}

.type-switch-group button {
  padding: 6px 18px;
}

.type-switch-group button:first-child {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.type-switch-group button:last-child {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
</style>
