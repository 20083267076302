<template>
  <div
    :class="{
      disabledComponent: isProjectCancelled,
    }"
  >
    <div class="mt-4">
      <ProjectDeliverable
        v-for="(deliverable, index) in deliverables"
        :ref="`project-deliverable-${index}`"
        :key="index"
        :index="index"
        :deliverable="deliverable"
        :project="project"
        :is-dirty="isDirty(index)"
        @preview="(emailTemplate) => preview(deliverable, index, emailTemplate)"
        @send="
          (emailTemplate) => sendDeliverable(deliverable, index, emailTemplate)
        "
      />
    </div>
    <div class="mt-3" v-if="$auth.user.isAgent">
      <button
        @click="eventBus.$emit(channels.addDeliverable)"
        type="button"
        class="btn btn-primary float-right"
        title="Add Deliverable"
        :disabled="isSubscriptionReadOnly() || isArchived"
      >
        <i class="fa fa-plus"></i><span> Add Deliverable</span>
      </button>
    </div>
  </div>
</template>

<script>
import timeMixin from "../../mixins/time";
import ProjectDeliverablePreviewModal from "./ProjectDeliverablePreviewModal.vue";
import ProjectDeliverable from "@/components/projects/ProjectDeliverable.vue";
import eventBus, { channels } from "@/eventBus";
import { getClientById } from "@/apis/clients";

export default {
  name: "ProjectDeliverables",
  components: { ProjectDeliverable },
  mixins: [timeMixin],
  props: {
    deliverables: Array,
    originalDeliverablesString: String,
    project: Object, // Needed for some details in the Deliverables preview modal
  },
  data() {
    return {
      client: {},
    };
  },
  computed: {
    isProjectCancelled() {
      return this.project.cancelled;
    },
    isArchived() {
      return Boolean(this.project.archived);
    },
    channels() {
      return channels;
    },
    eventBus() {
      return eventBus;
    },
    clientId() {
      return this.project?.client_id;
    },
  },
  watch: {
    clientId: async function () {
      if (this.clientId) this.client = await getClientById(this.clientId);
    },
  },
  methods: {
    isDirty(i) {
      if (this.originalDeliverablesString == null) return false;
      const arr = JSON.parse(this.originalDeliverablesString);
      return JSON.stringify(arr[i]) !== JSON.stringify(this.deliverables[i]);
    },
    sendDeliverable(deliverable, index, emailTemplate) {
      const d = { ...deliverable };
      delete d.files;
      delete d.type;

      this.$refs[`project-deliverable-${index}`]?.[0]?.setMode("preview");

      eventBus.$emit(channels.sendDeliverable, {
        deliverable: d,
        templateId: emailTemplate.id,
        isDirty: this.isDirty(index),
      });
    },
    /**
     * Public method used from parent page to open a particular row for editing (used on creating a new valuation)
     */
    preview(deliverable, index, emailTemplate) {
      const modalOptions = {
        name: "project-deliverables-preview-modal",
        height: "auto",
      };
      this.$modal.show(
        ProjectDeliverablePreviewModal,
        {
          deliverable,
          template: emailTemplate.body,
          language: emailTemplate.language,
          project: this.project,
        },
        modalOptions,
        {
          "before-close": async (e) => {
            const newValue = e.params?.value;
            if (!newValue) return;

            if (deliverable.type === "link" && !deliverable.link) {
              this.notifyError("Deliverable link is required");
              return;
            }

            this.sendDeliverable(deliverable, index, emailTemplate);
            eventBus.$emit(channels.saveDeliverable, index);
          },
        }
      );
    },
  },
  async mounted() {
    if (this.clientId) this.client = await getClientById(this.clientId);
  },
};
</script>

<style scoped>
h3 {
  color: rgb(74, 76, 78);
  font-weight: normal;
}
</style>
