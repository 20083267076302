<template>
  <Modal
    :show-close-button="true"
    @submit="onSubmitClick"
    @close="onCloseClick"
    cancel-text="Cancel"
    submit-text="Next step"
    :close-on-outside-click="false"
    :disable-submit-button="!selected"
    submit-class="btn-primary"
    :maxWidth="800"
  >
    <div class="container" style="text-align: center">
      <h3 class="modal-title">Create a Project</h3>
      <div class="d-flex justify-content-center" style="align-items: center">
        <div class="dot-container">
          <div class="dot dot-filled">1</div>
        </div>
        <div class="line"></div>
        <div class="dot-container">
          <div class="dot dot-empty">2</div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-2 mb-4">
        <div class="text"><b>Timeline structure</b></div>
        <div class="text">Project setup</div>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <div
          class="card"
          :class="{ selected: selected === 'linear' }"
          @click="toggleSelection('linear')"
        >
          <div class="body" style="height: 100%">
            <div class="d-flex">
              <div
                class="d-flex flex-column mr-4"
                style="align-items: center; width: 15px"
              >
                <div class="dot small-dot">
                  <i class="fa fa-check-circle" style="color: #17c2d7"></i>
                </div>
                <div class="vertical-line"></div>
                <div class="dot small-dot">
                  <i class="fa fa-check-circle" style="color: #17c2d7"></i>
                </div>
                <div class="vertical-line"></div>
                <div class="dot small-dot">
                  <i class="fa fa-check-circle" style="color: #17c2d7"></i>
                </div>
              </div>
              <div class="align-left">
                <p><strong>Linear timeline</strong></p>
                <p class="description">
                  Displays stages chronologically, allowing subsequent stages to
                  commence only after the previous ones have been completed,
                  which is ideal for projects with a sequential workflow.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card"
          :class="{ selected: selected === 'non-linear' }"
          @click="toggleSelection('non-linear')"
        >
          <div class="body" style="height: 100%">
            <div class="d-flex">
              <div
                class="d-flex flex-column mr-4"
                style="align-items: center; width: 15px"
              >
                <div class="dot small-dot">
                  <i class="fa fa-check-circle" style="color: #17c2d7"></i>
                </div>
                <div class="vertical-line" style="width: 0"></div>
                <div class="dot small-dot">
                  <i class="fa fa-circle-o" style="color: #17c2d7"></i>
                </div>
                <div class="vertical-line" style="width: 0"></div>
                <div class="dot small-dot">
                  <i class="fa fa-check-circle" style="color: #17c2d7"></i>
                </div>
              </div>
              <div class="align-left">
                <p><strong>Non-linear timeline</strong></p>
                <p class="description">
                  Enables initiating and completing project stages in any order,
                  providing flexibility in project management and execution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";

export default {
  name: "ChoseProjectTypeModal",
  components: { Modal },
  props: {
    disableSubmitButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteInput: "",
      selected: "linear",
    };
  },
  methods: {
    toggleSelection(option) {
      if (this.selected === option) {
        this.selected = null; // Deselect if clicked again
      } else {
        this.selected = option; // Select the clicked option
      }
    },
    onSubmitClick() {
      this.$emit("submit", this.selected);
    },
    onCloseClick() {
      this.$emit("close");
    },
  },
  computed: {
    matchedDeleteInput() {
      return this.deleteInput === "DELETE";
    },
  },
};
</script>
<style scoped>
.modal-title {
  margin-bottom: 20px;
  color: #17c2d7;
  font-size: 23px;
}
.description {
  color: #b1b1b1;
  font-size: 12px;
}

.dot {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.small-dot {
  width: 15px;
  height: 15px;
  background-color: white;
}

.dot-filled {
  background-color: #17c2d7; /* Blue color for the first dot */
  color: white;
}

.dot-empty {
  border: 1px solid #dde4ea; /* Border color for the second dot */
  color: #191f28;
}

.line {
  width: 106px; /* Adjust length of the line */
  height: 1px;
  background-color: #f5f5f5;
}
.vertical-line {
  width: 1px;
  height: 50px; /* Adjust height to your preference */
  background-color: #f5f5f5;
}
.card {
  border: 1px solid #dde4ea;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  font-family: Montserrat;
}

.card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.selected {
  border-color: #17c2d7;
  box-shadow: 0 0 5px rgba(23, 194, 215, 0.4);
}

.text,
.card {
  margin: 0 20px;
}
</style>
