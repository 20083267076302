<template>
  <wrapper-page>
    <div class="row clearfix">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="header">
            <h2>Email Templates</h2>
          </div>
          <div class="body">
            <div class="tab-bar-container body p-0" style="border: none">
              <div class="tab-bar-scroll">
                <ul class="nav nav-tabs">
                  <li class="nav-item mr-1">
                    <a
                      class="nav-link"
                      :class="{ active: activeTab === 'production' }"
                      @click="setActiveTab('production')"
                    >
                      Production
                    </a>
                  </li>
                  <li class="nav-item mr-1">
                    <a
                      class="nav-link"
                      :class="{ active: activeTab === 'reminders' }"
                      @click="setActiveTab('reminders')"
                    >
                      Reminders
                    </a>
                  </li>
                  <li class="nav-item mr-1">
                    <a
                      class="nav-link mr-1"
                      :class="{ active: activeTab === 'invites' }"
                      @click="setActiveTab('invites')"
                    >
                      Invites
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      :class="{ active: activeTab === 'surveys' }"
                      @click="setActiveTab('surveys')"
                    >
                      CSAT Surveys
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-lg-5 col-md-5">
                <b><label class="pt-4">Email Templates</label></b>
              </div>
              <div class="col-lg-7 col-md-7">
                <b><label class="pt-4">Email body</label></b>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-lg-5 col-md-5">
                <div class="row clearfix">
                  <div class="col-lg-6 col-md-6">
                    <ul class="list-group">
                      <li
                        v-for="(template, index) in selectedTabEmailsTemplate"
                        :key="`${template.id}-${template.title}`"
                        :class="[
                          'list-group-item',
                          'd-flex',
                          'justify-content-between',
                          'align-items-center',
                          {
                            'active-list': chosenTemplateIndex === index,
                          },
                          {
                            'inactive-list': chosenTemplateIndex !== index,
                          },
                        ]"
                        @click="
                          () => {
                            chosenTemplateIndex = index;
                            chosenMultiLingualTemplateIndex = 0;
                          }
                        "
                      >
                        <div
                          v-if="chosenTemplateIndex === index"
                          class="d-inline-block header"
                          style="padding-bottom: 0px !important"
                        >
                          <h2 style="font-size: 14px">
                            <strong>{{ template.title }}</strong>
                          </h2>
                        </div>
                        <div v-else class="d-inline-block">
                          {{ template.title }}
                        </div>
                        <div
                          class="header-dropdown dropdown d-inline-block px-1"
                        >
                          <div v-if="activeTab !== 'invites'" class="dropdown">
                            <a data-toggle="dropdown" aria-haspopup="true">
                              <img
                                src="/assets/icons/dots-three-circle.svg"
                                height="20px"
                                width="20px"
                                style="margin-top: -1px"
                                alt="menu"
                              />
                            </a>
                            <ul class="dropdown-menu text-nowrap">
                              <li v-for="item in menuItems" :key="item">
                                <a
                                  v-if="item === 'Clone template'"
                                  @click="cloneTemplate"
                                >
                                  <span class="fa fa-copy mr-1"></span>
                                  {{ item }}
                                </a>
                                <a
                                  v-if="item === 'Delete template'"
                                  @click="removeTemplate"
                                >
                                  <span class="fa fa-trash-o mr-1"></span>
                                  {{ item }}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <div
                        v-if="activeTab !== 'invites'"
                        class="align-right mt-2 mr-1"
                      >
                        <button
                          @click="addTemplate"
                          type="button"
                          class="btn btn-primary"
                          :title="
                            canCreateEmailTemplate
                              ? 'Add Email Template'
                              : 'You reached the email templates limit for your workspace.'
                          "
                          :disabled="
                            isSubscriptionReadOnly() || !canCreateEmailTemplate
                          "
                        >
                          <span class="sr-only">Add Email Template</span>
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </ul>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 d-flex flex-column"
                    style="background-color: #f7f8fa"
                  >
                    <div class="header pt-2 pb-4">
                      <h2><strong>Settings</strong></h2>
                    </div>
                    <div class="card" style="margin-bottom: 0">
                      <form-input-group
                        v-if="emailTemplate"
                        v-model="emailTemplate.title"
                        :field="templateFields[0]"
                        class="flex-grow-1"
                        :showLabel="true"
                      />
                      <form-input-group
                        :field="templateFields[1]"
                        v-model="emailTemplate.type"
                        :disabled="activeTab === 'reminders'"
                      />
                    </div>
                    <div class="divider mt-1 mb-1"></div>
                    <div class="mt-3 mb-3 d-flex flex-column flex-1">
                      <div class="header pb-1">
                        <h2 class="text-black">
                          <strong>Multi-language</strong>
                        </h2>
                      </div>
                      <div
                        class="pt-3 pb-3 flex-1 d-flex flex-column list-group"
                        style="overflow-y: auto"
                      >
                        <a
                          v-for="(
                            multiLingualTemplate, mli
                          ) in multiLingualTemplatesForSelectedTemplate"
                          :key="`${multiLingualTemplate.id}-${multiLingualTemplate.language}`"
                          @click="chosenMultiLingualTemplateIndex = mli"
                          role="button"
                          tabindex="-1"
                          class="header bg-white cursor-pointer d-flex align-items-center justify-content-between list-group-item"
                          :class="`${
                            chosenMultiLingualTemplateIndex === mli
                              ? 'active-list'
                              : 'inactive-list'
                          }`"
                          style="padding-bottom: 0.75rem; padding-top: 0.75rem"
                        >
                          <h2
                            style="font-size: 14px"
                            class="mb-0 mt-0 font-weight-normal"
                            :class="{
                              'text-black':
                                chosenMultiLingualTemplateIndex !== mli,
                            }"
                          >
                            {{
                              languageFromCode(multiLingualTemplate.language)
                                ?.name
                            }}
                          </h2>
                          <a
                            v-if="multiLingualTemplate.parent_id"
                            @click="removeLanguage(mli)"
                            role="button"
                            tabindex="-1"
                            class="cursor-pointer text-black"
                            title="remove language"
                          >
                            <i class="fa fa-trash-o"></i>
                          </a>
                        </a>
                      </div>
                      <div class="d-flex justify-content-end">
                        <button
                          v-if="emailTemplate?.id"
                          @click="showAddLanguageModal = true"
                          type="button"
                          class="btn btn-primary"
                          :disabled="!hasAvailableLanguagesToAdd"
                          :title="
                            hasAvailableLanguagesToAdd
                              ? 'Add Language'
                              : 'There are no more languages'
                          "
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 col-md-7 d-flex flex-column">
                <email-template-editor
                  ref="emailTemplateEditor"
                  v-model="multiLingualEmailTemplate.body"
                  :templateType="emailTemplate.type"
                  :language="multiLingualEmailTemplate.language ?? 'en'"
                />
                <div class="row mt-4">
                  <div class="col-md-7 d-flex justify-content-end">
                    <button
                      v-on:click="saveDetails"
                      :disabled="isSavingDetails || isSubscriptionReadOnly()"
                      class="btn btn-primary"
                    >
                      Save Changes
                    </button>
                  </div>
                  <div class="col-md-5 d-flex justify-content-end">
                    <button class="btn btn-info" @click="preview">
                      Preview
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <email-template-add-language-modal
        v-if="showAddLanguageModal"
        :exclude="usedLanguagesTemplate"
        @close="showAddLanguageModal = false"
        @submit="
          (language) => {
            addLanguage(language);
            showAddLanguageModal = false;
          }
        "
      />
    </div>
  </wrapper-page>
</template>

<script>
import _ from "lodash";
import WrapperPage from "../../components/layout/WrapperPage.vue";
import {
  addEmailTemplate,
  deleteEmailTemplateById,
  getEmailTemplates,
  updateEmailTemplate,
} from "@/apis/email-templates";
import EmailTemplateEditor from "../../components/ui/EmailTemplates/EmailTemplateEditor.vue";
import FormInputGroup from "../../components/ui/FormInputGroup.vue";
import EmailTemplatePreviewModal from "../../components/ui/Modals/EmailTemplatePreviewModal.vue";
import EmailTemplateAddLanguageModal from "@/components/ui/Modals/EmailTemplateAddLanguageModal.vue";
import { languageFromCode } from "@/utils/helper";
import { LANGUAGES } from "@/utils/app.constant";

export default {
  name: "SettingsEmailTemplatesPage",
  components: {
    EmailTemplateAddLanguageModal,
    WrapperPage,
    EmailTemplateEditor,
    FormInputGroup,
  },
  data() {
    return {
      showAddLanguageModal: false,
      isLoadingTemplates: false,
      isSavingDetails: false,
      emailTemplates: [],
      removedTemplates: [], // Keeps track of templates that were deleted.
      templateFields: [
        { label: "Template Title", name: "title" },
        { label: "Automatically loaded for", name: "type", type: "select" },
        { label: "Body", name: "body" },
      ],
      chosenTemplateIndex: 0,
      chosenMultiLingualTemplateIndex: 0,
      templateTypes: [
        {
          text: "First Preview",
          value: "first_preview",
          groupBy: ["production"],
        },
        { text: "First Revision", value: "first_rev", groupBy: ["production"] },
        { text: "Other Revision", value: "other_rev", groupBy: ["production"] },
        { text: "Last Revision", value: "last_rev", groupBy: ["production"] },
        {
          text: "Additional Revision (free)",
          value: "additional_rev_free",
          groupBy: ["production"],
        },
        {
          text: "Additional Revision (at a cost)",
          value: "additional_rev_cost",
          groupBy: ["production"],
        },
        { text: "Manual reminder", value: "reminder", groupBy: ["reminders"] },
        {
          text: "Feedback Auto reminder",
          value: "feedback_auto_reminder",
          groupBy: ["reminders"],
        },
        { text: "Contact Invite", value: "invite", groupBy: ["invites"] },
        {
          text: "Send Deliverable",
          value: "deliverable",
          groupBy: ["production"],
        },
        { text: "Survey", value: "survey", groupBy: ["surveys"] },
        { text: "None", value: "none", groupBy: ["production"] },
      ],
      menuItems: ["Clone template", "Delete template"],
      activeTab: "production",
    };
  },
  computed: {
    canCreateEmailTemplate() {
      return this.$store.getters.messageTemplatesRemaining !== 0;
    },
    selectedTabEmailsTemplate() {
      const filteredTemplate = this.emailTemplates.filter((template) => {
        return (
          !template.parent_id &&
          this.templateTypes.some(
            (templateType) =>
              templateType.value === template?.type &&
              templateType.groupBy.includes(this.activeTab)
          )
        );
      });
      return filteredTemplate;
    },
    multiLingualTemplatesForSelectedTemplate() {
      const defaultLanguageTemplate = this.emailTemplate;

      if (defaultLanguageTemplate) {
        const otherLanguageTemplates = this.emailTemplates.filter(
          (t) => t.parent_id && t.parent_id === defaultLanguageTemplate.id
        );

        return [defaultLanguageTemplate, ...otherLanguageTemplates];
      }

      return [];
    },
    emailTemplate() {
      return (
        this.selectedTabEmailsTemplate[this.chosenTemplateIndex ?? 0] || {}
      );
    },
    multiLingualEmailTemplate() {
      return (
        this.multiLingualTemplatesForSelectedTemplate[
          this.chosenMultiLingualTemplateIndex ?? 0
        ] || {}
      );
    },
    usedLanguagesTemplate() {
      return this.multiLingualTemplatesForSelectedTemplate.map(
        (t) => t.language
      );
    },
    hasAvailableLanguagesToAdd() {
      const usedSupportedLanguages = _.intersection(
        this.usedLanguagesTemplate,
        LANGUAGES.map((l) => l["1"])
      ); // removes languages that are not supported anymore

      return usedSupportedLanguages.length < LANGUAGES.length;
    },
  },
  methods: {
    languageFromCode,
    removeLanguage(mli) {
      const templateToBeRemoved =
        this.multiLingualTemplatesForSelectedTemplate?.[mli];

      if (!templateToBeRemoved) {
        return; // Exit if no template to remove
      }

      const removingTemplateIndex = this.findTemplateIndex(templateToBeRemoved);
      const removedTemplate = this.emailTemplates.splice(
        removingTemplateIndex,
        1
      )[0];

      this.addToRemovedTemplates(removedTemplate);
      this.updateChosenTemplateIndex();
      this.notifyDeletion(removedTemplate);
    },
    addLanguage(language) {
      const emailTemplates = [...this.emailTemplates];
      emailTemplates.push({
        title: this.emailTemplate?.title,
        body: "",
        type: this.emailTemplate?.type,
        language: language,
        parent_id: this.emailTemplate?.id,
      });

      this.emailTemplates = emailTemplates;

      if (this.multiLingualTemplatesForSelectedTemplate.length > 0)
        this.chosenMultiLingualTemplateIndex =
          this.multiLingualTemplatesForSelectedTemplate.length - 1;
      else this.chosenMultiLingualTemplateIndex = 0;
    },
    preview() {
      const modalOptions = {
        name: "email-template-preview-modal",
        height: "auto",
      };
      this.$modal.show(
        EmailTemplatePreviewModal,
        {
          template: this.multiLingualEmailTemplate.body,
          language: this.multiLingualEmailTemplate.language,
        },
        modalOptions
      );
    },
    setActiveTab(tabName) {
      this.activeTab = tabName;
      this.templateFields[1].options = this.templateTypes.filter((type) =>
        type.groupBy.includes(tabName)
      );
      this.chosenTemplateIndex = 0;
      this.chosenMultiLingualTemplateIndex = 0;
    },
    addTemplate() {
      this.emailTemplates.push({
        title: "New Template",
        language: "en",
        body: `Hello [client name]<br/><br/>...<br/><br/>Best regards,<br/>[user name]`,
        type:
          this.activeTab === "reminders"
            ? this.getReminderType()
            : this.templateFields[1]?.options?.[0]?.value || "first_preview",
      });
      this.chosenTemplateIndex = this.selectedTabEmailsTemplate.length - 1;
      this.chosenMultiLingualTemplateIndex = 0;
    },
    getReminderType() {
      const hasManualReminder = this.emailTemplates.some(
        (template) => template.type === "reminder"
      );
      const hasAutoReminder = this.emailTemplates.some(
        (template) => template.type === "feedback_auto_reminder"
      );

      return hasManualReminder && !hasAutoReminder
        ? "feedback_auto_reminder"
        : "reminder";
    },
    cloneTemplate() {
      let newTemplate = JSON.parse(
        JSON.stringify(this.emailTemplate, (key, value) =>
          key === "id" ? undefined : value
        )
      );
      newTemplate.title += " Copy";
      delete newTemplate.id;
      delete newTemplate.created_on;
      delete newTemplate.updated_on;
      delete newTemplate.tenant_id;
      this.emailTemplates = [...this.emailTemplates, newTemplate];
    },
    removeTemplate() {
      const templateToBeRemoved = this.getSelectedTemplate();

      if (!templateToBeRemoved) {
        return; // Exit if no template to remove
      }

      const removingTemplateIndex = this.findTemplateIndex(templateToBeRemoved);
      const removedTemplate = this.emailTemplates.splice(
        removingTemplateIndex,
        1
      )[0];

      // also remove its children (multi-lingual) templates
      if (templateToBeRemoved.id) {
        this.emailTemplates
          .filter((t) => t.parent_id && t.parent_id === templateToBeRemoved.id)
          .forEach((template) => {
            const index = this.findTemplateIndex(template);
            const removed = this.emailTemplates.splice(index, 1)[0];
            this.addToRemovedTemplates(removed);
          });
      }

      this.addToRemovedTemplates(removedTemplate);
      this.updateChosenTemplateIndex();
      this.notifyDeletion(removedTemplate);
    },

    getSelectedTemplate() {
      return this.selectedTabEmailsTemplate[this.chosenTemplateIndex];
    },
    findTemplateIndex(templateToBeRemoved) {
      if (templateToBeRemoved.id !== undefined) {
        return this.emailTemplates.findIndex(
          (template) => template.id === templateToBeRemoved.id
        );
      } else {
        return this.emailTemplates.findIndex(
          (template) =>
            template.title === templateToBeRemoved.title &&
            template.type === templateToBeRemoved.type
        );
      }
    },
    addToRemovedTemplates(removedTemplate) {
      if (removedTemplate.id) {
        this.removedTemplates.push(removedTemplate.id);
      }
    },
    updateChosenTemplateIndex() {
      this.chosenTemplateIndex = Math.min(
        this.chosenTemplateIndex,
        this.selectedTabEmailsTemplate.length - 1
      );
      this.chosenMultiLingualTemplateIndex = 0;
    },
    notifyDeletion(removedTemplate) {
      this.notifyWarn(
        "Template - " +
          removedTemplate.title +
          ' - will be deleted when you click on "Save Changes"',
        "Edit Email Templates"
      );
    },
    enforceMultiLingualTemplatesAsParent() {
      // make sure all language variants of a template have the same title and type as their parent
      this.emailTemplates
        .filter((t) => !t.parent_id) // get only the parents
        .forEach((t) => {
          for (let u = 0; u < this.emailTemplates.length; u++) {
            const template = this.emailTemplates[u];
            if (template.parent_id && template.parent_id === t.id) {
              this.emailTemplates[u].title = t.title;
              this.emailTemplates[u].type = t.type;
            }
          }
        });
    },
    async saveDetails() {
      this.isSavingDetails = true;
      this.enforceMultiLingualTemplatesAsParent();

      try {
        // Delete removed templates
        for (let u = 0; u < this.removedTemplates.length; u++) {
          await deleteEmailTemplateById(this.removedTemplates);
        }
        // Add new templates &
        // Update others
        let promises = [];
        for (let u = 0; u < this.emailTemplates.length; u++) {
          const template = this.emailTemplates[u];
          if ("id" in template) {
            promises.push(updateEmailTemplate(template));
          } else {
            promises.push(addEmailTemplate(template));
          }
        }
        await Promise.allSettled(promises);
        this.notifySuccess("Email Template saved");
        this.emailTemplates = await getEmailTemplates();
        this.$store.commit("SET_EMAIL_TEMPLATES", this.emailTemplates);
      } catch (err) {
        this.notifyError(err, "Failed to save Email Templates");
      }
      this.isSavingDetails = false;
    },
  },
  watch: {
    chosenTemplateIndex: function (new_val) {
      if (this.emailTemplates[new_val].id > 0) {
        this.$refs.emailTemplateEditor.focus();
      } else {
        document.getElementById("input-title").focus();
      }
    },
    "$store.getters.emailTemplates": {
      handler(newTemplates) {
        this.emailTemplates = newTemplates;
      },
      immediate: true,
    },
  },
  created() {
    if (!this.$auth.user.isAgent) {
      this.$router.push("/projects/");
    }
    this.templateFields[1].options = this.templateTypes.filter((type) =>
      type.groupBy.includes(this.activeTab)
    );
  },
  async mounted() {
    if (
      !this.$auth.user.owner &&
      !this.$auth.user.admin &&
      !this.$auth.user.email_templates
    )
      return this.$router.push("/");

    if (window.location.hash === "#reminders") {
      this.setActiveTab("reminders");
    }
    this.chosenTemplateIndex = 0;
    this.chosenMultiLingualTemplateIndex = 0;
  },
};
</script>
<style scoped>
.active-list {
  background-color: #f7f8fa;
  border-bottom: 1px solid #dde4ea;
  border-left: 1px solid #dde4ea;
  border-right: 1px solid #dde4ea;
  margin-bottom: 0px;
  /* border-radius: 0.25rem; */
}

.inactive-list {
  margin-bottom: 0px;
  border-bottom: 1px solid #dde4ea;
  /* border-radius: 0.25rem; */
}
</style>
