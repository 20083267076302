<template>
  <div class="col-lg-8 col-sm-12 pl-0 mt-4">
    <template v-if="!revisionIsInProduction">
      <a
        v-if="!files?.length"
        :href="reviewLink"
        ref="btnReviewLink"
        target="_blank"
        class="text-black font-weight-bold custom-underline"
      >
        Click to review
      </a>
      <p class="mt-4">
        Time left to provide feedback:
        <strong>{{ timeLeftForFeedback }}</strong>
      </p>
      <revision-files v-if="files?.length" :files="files" />
      <!--
        <stage-quick-approve-or-reject
        class="ml-3"
        :stage="revisionStage"
        @on_stage_approve_click="approve()"
        @on_stage_reject_click="requestRevision()"
      />
      -->
      <div class="mt-4 mb-2">
        <button
          class="btn btn-sm btn-danger mr-4"
          @click="requestRevision"
          title="Reject Stage"
        >
          Reject
        </button>
        <button
          class="btn btn-sm btn-success"
          @click="approve"
          title="Approve Stage"
        >
          Approve
        </button>
      </div>
      <div class="my-4 d-flex flex-column custom-textarea">
        <!-- Add the container div and Flexbox classes -->
        <textarea
          class="form-control mt-4 text-black"
          rows="5"
          v-model="emailText"
        ></textarea>

        <div class="d-flex justify-content-end mt-2">
          <button
            class="btn btn-info btn-sm"
            type="button"
            @click="sendMessage"
          >
            Send
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import RevisionFiles from "@/components/ui/Timeline/RevisionFiles.vue";
import timeMixin from "../../../mixins/time";
import workflowMixin from "../../../mixins/workflow";
import xbytes from "xbytes";

const { DateTime } = require("luxon-business-days");

export default {
  name: "RevisionClientTimeline",
  components: { RevisionFiles },
  mixins: [timeMixin, workflowMixin],
  props: {
    revision: Object, // Task (Revision)
    workflow: Object, // Workflow (needed?)
    project: Object,
    files: Array,
  },
  data() {
    return {
      emailText: "",
    };
  },
  computed: {
    reviewLink() {
      return this.makeTrackableLink(this.revision.id, this.$auth.tenant.domain);
    },
    revisionIsInProduction() {
      return (
        this.revision.status !== "awaiting_feedback" &&
        !this.revision.completed_on
      );
    },
    lastReviewLink() {
      return (
        this.revision?.data?.review_link ??
        this.getPreviousRevision(this.revision, this.workflow)?.data
          ?.review_link
      );
    },
    revisionStage() {
      return this.getRevisionParentStage(this.revision, this.workflow);
    },
    timeLeftForFeedback() {
      const daysForFeedback = this.project.days_allowed_for_client_feedback;
      let until = DateTime.fromISO(this.revision.sent_on);
      if (!until.isValid) {
        return daysForFeedback + " business days";
      }
      until = until.plusBusiness({ days: daysForFeedback }).toJSDate();

      return this.timeElapsedBetween(new Date(), until);
    },
  },
  methods: {
    humanizeBytes(bytes) {
      if (!bytes) return "";

      return xbytes(bytes);
    },
    sendMessage() {
      let event = {
        event_id: this.revision.id,
        emailText: this.emailText,
      };
      this.$emit("revision_send_message_click", event);
    },
    approve() {
      this.$emit("revision_client_approve_click", this.revision);
    },
    requestRevision() {
      this.$emit("revision_client_reject_click", this.revision);
    },
  },
};
</script>

<style scoped>
.btn-primary {
  background-color: #5994b8;
  border-color: #5994b8;
}

.btn-success {
  background-color: #34ba69;
  border-color: #34ba69;
}

.btn-warning {
  background-color: #e57671;
  border-color: #e57671;
  color: white;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.custom-underline {
  text-decoration: underline;
}

.custom-textarea {
  width: 75%; /* Reduce the width by 25% */
}

.btn-sm {
  width: 7rem;
}
</style>
