<template>
  <!-- Top/Header of main area -->
  <div class="block-header">
    <div class="row clearfix">
      <div class="col-md-6 col-sm-12">
        <h1>{{ this.$route.meta.label || this.$route.name }}</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">{{ this.$appName }}</router-link>
            </li>
            <li
              class="breadcrumb-item"
              v-for="page in parentPages"
              v-bind:key="page.path"
            >
              <router-link :to="page.path">{{ page.name }}</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ currentPage }}
            </li>
          </ol>
        </nav>
      </div>
      <slot name="MainContentHeaderActions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    parentPages() {
      var fullPath = this.$route.path.replace(/^\//, "").replace(/\/$/, ""); // Trim leading or ending '/'
      let path = "";
      let pages = [];
      for (let i = 0; i < fullPath.split("/").length - 1; i++) {
        const name = fullPath.split("/")[i];
        path += "/" + name;
        pages.push({
          path,
          name,
        });
      }
      return pages;
    },
    currentPage() {
      return this.$route.path
        .replace(/^\//, "")
        .replace(/\/$/, "")
        .split("/")
        .slice(-1)[0]
        .replace(/-/, " ");
    },
  },
};
</script>

<style scoped>
.breadcrumb-item {
  text-transform: capitalize;
}
</style>
