<template>
  <wrapper-page>
    <template v-slot:MainContentHeaderActions>
      <div
        v-if="$auth.user.isAgent"
        class="col-md-6 col-sm-12 text-right hidden-xs"
      >
        <button
          v-if="isProjectCompleted && !project.archived"
          @click="isProjectArchiveOrUnarchiveClick = true"
          class="btn btn-warning mr-2"
          :disabled="!canArchiveProjects || isSubscriptionReadOnly()"
          :title="
            !canArchiveProjects &&
            'You reached the archived projects limit for your workspace.'
          "
        >
          Archive Project
        </button>
        <router-link
          :to="{ path: `/projects/${projectId}` }"
          class="btn btn-primary ml-2"
          ><i class="icon-equalizer"></i>
          <span>Project Settings</span></router-link
        >
        <router-link
          :to="{ path: `/clients/${project.client_id}` }"
          class="btn btn-primary ml-2"
          ><i class="icon-settings"></i>
          <span>Client Settings</span></router-link
        >
      </div>
    </template>

    <div class="d-flex align-items-center" style="padding-bottom: 1.5rem">
      <h1
        class="d-inline-block mr-2"
        style="margin-bottom: 0; line-height: 1.7rem"
      >
        {{ project.name }}
      </h1>
      <span
        v-if="project.archived"
        :class="[
          'badge',
          'xs',
          project.cancelled ? 'badge-disabled' : 'badge-primary',
        ]"
        >{{ project.cancelled ? "cancelled" : "Archived" }}</span
      >
    </div>
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card">
          <div class="body">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#timeline"
                  :class="activeTab == 'timeline' ? `active` : ``"
                  @click="activeTab = 'timeline'"
                  >Timeline</a
                >
              </li>
              <li class="nav-item" v-if="isShowProjectScope">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#brief"
                  :class="activeTab == 'brief' ? `active` : ``"
                  @click="activeTab = 'brief'"
                  >Project Scope</a
                >
              </li>
              <li v-if="showNotesTab" class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#notes"
                  :class="activeTab == 'notes' ? `active` : ``"
                  @click="activeTab = 'notes'"
                  >Notes</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#deliverables"
                  :class="activeTab == 'deliverables' ? `active` : ``"
                  @click="activeTab = 'deliverables'"
                  >Deliverables</a
                >
              </li>
              <li class="nav-item" v-if="$auth.user.isAgent">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#analytics"
                  :class="activeTab == 'analytics' ? `active` : ``"
                  @click="activeTab = 'analytics'"
                  >Analytics</a
                >
              </li>
              <li class="nav-item" v-if="showFeedbackTab">
                <a
                  class="nav-link"
                  href="#feedback"
                  :class="activeTab == 'feedback' ? `active` : ``"
                  @click="activeTab = 'feedback'"
                  >Feedback</a
                >
                <!-- <router-link
                  class="nav-link"
                  :to="{ name: 'Feedback', query: { tab: 'feedback' } }"
                  :class="{ active: activeTab === 'feedback' }"
                  @click="activeTab = 'feedback'"
                >
                  Feedback
                </router-link> -->
              </li>
            </ul>
            <div class="tab-content">
              <div
                class="tab-pane"
                id="timeline"
                :class="activeTab == 'timeline' ? `active` : ``"
              >
                <timeline-list
                  v-if="!loadingTimeline"
                  :model="this.timeline"
                  :root="this.timeline"
                  :project="this.project"
                  :events="events"
                  :notes="notes"
                  :surveysFeedback="surveysFeedback"
                  @add="addNote"
                  @goto_tab="(e) => (activeTab = e)"
                />
              </div>
              <div
                class="tab-pane"
                id="brief"
                :class="activeTab == 'brief' ? `active` : ``"
              >
                <a
                  v-if="project.brief_document_link"
                  :href="project.brief_document_link"
                  target="_blank"
                  >Click here to open brief in another tab</a
                >
                <div v-else-if="isShowProjectScope" class="row clearfix">
                  <div class="col-12">
                    <project-scope-tab
                      :timeline="timeline"
                      :project_id="project.id"
                      :projectScopes="projectScopes"
                      :project="this.project"
                      @update="updateScope"
                      @delete="deleteScope"
                      @add="addScope"
                    />
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                id="notes"
                :class="activeTab == 'notes' ? `active` : ``"
              >
                <div class="row clearfix">
                  <div class="col-12">
                    <notes-tab
                      :timeline="timeline"
                      :project_id="project.id"
                      :notes="notes"
                      :project="this.project"
                      @update="updateNote"
                      @delete="deleteNote"
                      @add="addNote"
                    />
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                id="deliverables"
                :class="activeTab == 'deliverables' ? `active` : ``"
              >
                <div class="row clearfix">
                  <div class="col-12 col-md-10">
                    <project-deliverables
                      :deliverables="deliverables"
                      :originalDeliverablesString="originalDeliverablesString"
                      :project="project"
                      ref="project-deliverables"
                    />
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                id="analytics"
                v-if="$auth.user.isAgent"
                :class="activeTab == 'analytics' ? `active` : ``"
              >
                <div
                  class="row clearfix"
                  style="background: #f7f9fb !important"
                >
                  <div class="col-12 pt-4">
                    <project-analytics
                      :project="project"
                      :workflow="timeline"
                      v-if="!loadingTimeline"
                    />
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                id="feedback"
                :class="activeTab == 'feedback' ? `active` : ``"
              >
                <div class="row clearfix">
                  <div class="col-md-12 col-sm-12 col-lg-9 pt-2 pl-4">
                    <feedback-tab
                      v-if="activeTab == 'feedback'"
                      :project="project"
                      :surveysFeedback="surveysFeedback"
                      :surveysStatus="surveysStatus"
                    ></feedback-tab>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ActionConfirmModal
      v-if="isProjectArchiveOrUnarchiveClick"
      :title="projectArchiveModalProps.title"
      :text="projectArchiveModalProps.text"
      :submitText="projectArchiveModalProps.submitText"
      @close="isProjectArchiveOrUnarchiveClick = false"
      @submit="
        () => {
          archiveProject(true);
          isProjectArchiveOrUnarchiveClick = false;
        }
      "
    />
  </wrapper-page>
</template>

<script>
import WrapperPage from "../components/layout/WrapperPage.vue";
import TimelineList from "../components/ui/Timeline/TimelineList.vue";
import FeedbackTab from "../components/ui/FeedbackSurvey/FeedbackTab.vue";
import ProjectDeliverables from "../components/projects/ProjectDeliverables.vue";
import ActionConfirmModal from "../components/ui/Modals/ActionConfirmModal.vue";
import {
  archiveProjectById,
  deleteDeliverable,
  getDeliverableFiles,
  getProjectById,
  getProjectDeliverables,
  getProjectTimelineEvents,
  saveProjectDeliverable,
  sendProjectDeliverable,
} from "@/apis/projects";
import { getWorkflowById } from "@/apis/workflows";
import ProjectAnalytics from "../components/projects/ProjectAnalytics.vue";
import NotesTab from "../components/ui/Notes/NotesTab.vue";
import { getNotes } from "../apis/notes";
import notesFormatterMixin from "../mixins/notesFormatter";
import workflowMixin from "../mixins/workflow";
import ProjectScopeTab from "../components/ui/ProjectScope/ProjectScopeTab.vue";
import { getScope } from "../apis/project-scope";
import { getAllSurveyWithFeedback, surveysStatus } from "../apis/survey";
import eventBus, { channels } from "@/eventBus";

export default {
  components: {
    WrapperPage,
    TimelineList,
    ProjectDeliverables,
    ProjectAnalytics,
    NotesTab,
    ProjectScopeTab,
    FeedbackTab,
    ActionConfirmModal,
  },
  mixins: [notesFormatterMixin, workflowMixin],
  data() {
    return {
      loadingTimeline: true,
      activeTab: "timeline",
      project: {},
      projectId: this.$route.params.projectId,
      timeline: {},
      events: [],
      notes: [],
      projectScopes: [],
      surveysFeedback: [],
      surveysStatus: [],
      deliverables: [],
      originalDeliverablesString: null, // Used to keep track of changes to the deliverables list
      isProjectArchiveOrUnarchiveClick: false,
      projectArchiveModalProps: {
        submitText: "Ok",
        title: "Are you sure you want to archive this project?",
        text: "This project will be hidden and can be found in the archived projects section.",
      },
    };
  },
  computed: {
    showNotesTab() {
      return (
        this.$auth.user.isAgent ||
        this.$store.state.tenantProfile?.show_production_details_in_timeline !==
          false
      );
    },
    canArchiveProjects() {
      return this.$store.getters.archivedProjectsRemaining !== 0;
    },
    isShowProjectScope() {
      const user = this.$auth.user;
      const scopeSettings = this.projectScopes[0];
      if (user.admin || user.owner) {
        return true;
      }
      if (!user.isAgent && this.projectScopes.length) {
        return scopeSettings?.visible_to_client;
      }
      return scopeSettings?.visible_to_agency;
    },
    isLastStageAmongAll() {
      // Get the current stage from the timeline.
      const currentStage = this.getCurrentStage(this.timeline);

      // If the current stage is undefined, it means all stages are completed, so return true.
      // Otherwise, check if the current stage is the last stage in the timeline.
      return (
        currentStage === undefined ||
        this.isLastStage(currentStage, this.timeline)
      );
    },
    isProjectCompleted() {
      return (
        this.getProductionProgress(
          this.timeline,
          this.project.is_survey_sent,
          this.project.send_csat_survey,
          this.project.survey_completed
        ) === 100
      );
    },
    showFeedbackTab() {
      if (this.$auth.user.isAgent) {
        return this.isLastStageAmongAll;
      }
      return this.project?.is_survey_sent;
    },
  },
  async created() {
    if (isNaN(Number(this.projectId))) {
      this.$router.push("/projects/");
      return;
    }

    this.loadData();
  },
  methods: {
    async getAndUpdateProjectData(projectId) {
      const projectData = await getProjectById(projectId, true);
      this.events = await getProjectTimelineEvents(this.projectId);
      const cancelProjectEvent = this.events.find(
        (e) => e.category === "Cancel Project"
      );
      const sendPreviewEvents = this.events.filter(
        (e) => e.category === "Send Preview"
      );
      const rejectEvent = this.events.filter(
        (e) =>
          e.category === "Revision Rejected" || e.category === "Stage Rejected"
      );

      if (projectData && cancelProjectEvent) {
        projectData.cancelEventInfo = JSON.parse(cancelProjectEvent.event);
        projectData.cancelEventInfo.event_time = cancelProjectEvent.event_time;
      }
      if (projectData && sendPreviewEvents.length) {
        projectData.sendPreviewEvents = sendPreviewEvents.map(
          (previewEvent) => {
            const eventObj = JSON.parse(previewEvent.event);
            eventObj.event_time = previewEvent.event_time;
            eventObj.user_id = previewEvent.user_id;
            return eventObj;
          }
        );
      }
      if (projectData && rejectEvent.length) {
        projectData.rejectEvents = rejectEvent.map((rEvent) => {
          const eventObj = JSON.parse(rEvent.event);
          eventObj.event_time = rEvent.event_time;
          eventObj.user_id = rEvent.user_id;
          return eventObj;
        });
      }
      return projectData;
    },
    async loadData() {
      try {
        this.loadingTimeline = true;
        this.project = await this.getAndUpdateProjectData(this.projectId);
        this.timeline = await getWorkflowById(this.project.workflow_id);
        await this.refreshAndLoadDeliverables();
      } catch (err) {
        this.notifyError(err, "Error on loading project data");
        if (this.project.id && !this.timeline.id) {
          this.$router.push("/projects/" + this.projectId);
        } else {
          this.$router.push("/projects/");
        }
        return;
      }

      try {
        // If the first stage doesn't have a started_on set, we set it as the project's start_date
        if (this.is_linear && !this.timeline.tasks[0].started_on) {
          this.timeline.tasks[0].started_on = this.project.start_date;
        }
      } catch (err) {
        this.notifyError(err, "Error on loading project deliverables");
      } finally {
        this.loadingTimeline = false;
      }
      if (window.location.hash === "#feedback") {
        this.activeTab = "feedback";
      }
      this.surveysFeedback = await getAllSurveyWithFeedback(this.projectId);
      this.surveysStatus = await surveysStatus();
    },
    async refreshAndLoadDeliverables() {
      const result = await getProjectDeliverables(this.projectId);
      if (result && result.length > 0) {
        this.deliverables = result.map((d) => {
          if (!d.files) d.files = [];

          return d;
        });

        this.deliverables.forEach((deliverable, i) => {
          if (!this.projectId || !deliverable.id) return;

          getDeliverableFiles(this.projectId, deliverable.id)
            .then((res) => {
              const files = res.map((f) => {
                const storageEndpoint = process.env.VUE_APP_AZ_STORAGE_ENDPOINT;
                const url = `${storageEndpoint}${
                  storageEndpoint.at(-1) !== "/" ? "/" : ""
                }documents/${f.path}`;
                const filename = url.substring(url.lastIndexOf("/") + 1);

                let thumbnail = null;
                const type = `.${filename.split(".")[1]?.toLowerCase()}`;
                if (
                  [
                    ".jpg",
                    ".png",
                    ".svg",
                    ".gif",
                    ".jpeg",
                    ".bmp",
                    ".webm",
                    ".ogg",
                    ".mp4",
                    ".avi",
                    ".mkv",
                    ".wmv",
                    ".mov",
                  ].includes(type)
                ) {
                  thumbnail = url.replace(filename, `thumbnails/${filename}`);
                }

                return {
                  url,
                  size: f.size,
                  created_on: f.created_on,
                  thumbnail,
                  name: filename,
                  type,
                };
              });

              this.$set(this.deliverables, i, {
                ...deliverable,
                files,
                type: files.length ? "files" : "link",
              });
            })
            .catch((error) => {
              this.notifyError(error, "Error getting files for deliverable");
            });
        });
      } else if (this.$auth.user.isAgent) {
        this.deliverables = [{ title: null, body: null, files: [] }];
      }
      this.originalDeliverablesString = JSON.stringify(this.deliverables);
    },
    async archiveProject(shouldArchive) {
      try {
        await archiveProjectById(this.projectId, shouldArchive);
        this.notifySuccess("Project Archived");
        this.$router.push("/projects/");
      } catch (err) {
        this.notifyError(err, "Error on archiving project");
      }
    },
    async saveDeliverable(deliverable) {
      try {
        await saveProjectDeliverable(this.projectId, deliverable);
        this.notifySuccess("Deliverable Saved");
      } catch (err) {
        this.notifyError(err, "Error on saving deliverable");
      }
    },
    async sendDeliverable(deliverable, templateId, isDirty) {
      try {
        if (isDirty || !deliverable.id) {
          deliverable = await saveProjectDeliverable(
            this.projectId,
            deliverable
          );
          this.originalDeliverablesString = JSON.stringify(this.deliverables);
        }
        await sendProjectDeliverable(this.projectId, deliverable, templateId);
        this.notifySuccess("Deliverable Sent");
      } catch (err) {
        this.notifyError(err, "Error on sending deliverable");
      }
    },
    async getAllNotes(projectId) {
      try {
        const allnotes = await getNotes(projectId);
        allnotes.forEach((element) => {
          this.formatNotes(element, this.timeline);
        });
        this.notes = allnotes;
      } catch (error) {
        this.notifyError(error, "Error fetching notes");
      }
    },
    async getAllScope(projectId) {
      try {
        const allScope = await getScope(projectId);
        allScope.forEach((element) => {
          this.formatNotes(element, this.timeline);
        });
        this.projectScopes = allScope;
      } catch (error) {
        this.notifyError(error, "Error fetching scope");
      }
    },
    // Notes CRUD helper functions
    updateNote(targetNoteIndex, updatedNote) {
      this.notes[targetNoteIndex] = updatedNote;
    },
    deleteNote(selectedNoteId) {
      this.notes = this.notes.filter((note) => note.id !== selectedNoteId);
    },
    addNote(newNote) {
      this.notes.push(newNote);
    },

    //Project Scop CRUD helper functions
    updateScope(targetScopeIndex, updatedScope) {
      this.projectScopes[targetScopeIndex] = updatedScope;
    },
    deleteScope(selectedScopeId) {
      this.projectScopes = this.projectScopes.filter(
        (scope) => scope.id !== selectedScopeId
      );
    },
    addScope(newScope) {
      this.projectScopes.push(newScope);
    },
  },
  watch: {
    timeline() {
      this.getAllNotes(this.projectId);
      this.getAllScope(this.projectId);
      this.project.workflow = this.timeline;
    },
  },
  async mounted() {
    eventBus.$on(channels.fetchProject, async () => {
      this.project = await this.getAndUpdateProjectData(this.projectId);
    });
    eventBus.$on(channels.refreshProject, async () => {
      this.loadData();
    });
    eventBus.$on(channels.fetchTimeline, async () => {
      this.timeline = await getWorkflowById(this.project.workflow_id);
    });
    eventBus.$on(channels.fetchAllSurveyWithFeedback, async () => {
      this.surveysFeedback = await getAllSurveyWithFeedback(this.projectId);
    });
    eventBus.$on(channels.fetchAllSurveyStatus, async () => {
      this.surveysStatus = await surveysStatus();
    });
    eventBus.$on(channels.addDeliverable, async () => {
      this.deliverables.push({
        type: "link",
        title: null,
        body: null,
        files: [],
      });
    });
    eventBus.$on(channels.updateDeliverable, async ({ index, data }) => {
      this.$set(this.deliverables, index, data);
    });
    eventBus.$on(channels.deleteDeliverable, async (index) => {
      const deletedDeliverable = this.deliverables[index];

      if (!deletedDeliverable) return;

      deleteDeliverable(this.projectId, deletedDeliverable.id);

      this.deliverables.splice(index, 1);
    });
    eventBus.$on(channels.saveDeliverable, async (index) => {
      const deliverable = { ...this.deliverables[index] };
      delete deliverable.files;
      delete deliverable.type;

      this.$refs["project-deliverables"].$refs[
        `project-deliverable-${index}`
      ]?.[0]?.setMode("preview");

      this.saveDeliverable(deliverable);
    });
    eventBus.$on(channels.sendDeliverable, async (data) => {
      this.sendDeliverable(data.deliverable, data.templateId, data.isDirty);
    });

    if (this.$route.fullPath.endsWith("#deliverables")) {
      this.activeTab = "deliverables";
    }
  },
  beforeDestroy() {
    eventBus.$off(channels.fetchProject);
    eventBus.$off(channels.refreshProject);
    eventBus.$off(channels.fetchTimeline);
    eventBus.$off(channels.fetchAllSurveyWithFeedback);
    eventBus.$off(channels.fetchAllSurveyStatus);
    eventBus.$off(channels.addDeliverable);
    eventBus.$off(channels.updateDeliverable);
    eventBus.$off(channels.deleteDeliverable);
    eventBus.$off(channels.saveDeliverable);
    eventBus.$off(channels.sendDeliverable);
  },
};
</script>

<style scoped>
.btn-primary {
  background-color: #16a1b8;
  border-color: #16a1b8;
}
</style>
