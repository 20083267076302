<template>
  <div style="padding-top: 20px; padding-bottom: 20px">
    <button
      v-if="!isLastRevision && revision.status == 'awaiting_feedback'"
      class="btn btn-danger mr-2"
      @click="reject()"
      :disabled="isSubscriptionReadOnly()"
    >
      Revisions requested
    </button>
    <button
      v-if="revision.status == 'awaiting_feedback'"
      class="btn btn-primary mr-2"
      @click="$emit('open_revision_for_reminder', revision.id)"
      :disabled="isSubscriptionReadOnly()"
    >
      Send reminder
    </button>
    <button
      v-if="revision.status !== 'in_progress'"
      class="btn custom-success mr-2"
      @click="approve()"
      :disabled="isSubscriptionReadOnly()"
    >
      Client approved
    </button>
  </div>
</template>

<script>
import workflowMixin from "../../../mixins/workflow";

export default {
  name: "TimelineRevisionAgencyActionButtons",
  mixins: [workflowMixin],
  props: {
    revision: Object, // Task (Revision)
    workflow: Object, // Workflow (needed?)
  },
  computed: {
    isLastRevision() {
      const stage = this.getRevisionParentStage(this.revision, this.workflow);
      return this.isLastRevisionInStage(this.revision, stage);
    },
  },
  methods: {
    reject() {
      this.$emit("revision_reject_revision_click", this.revision);
    },
    approve() {
      this.$emit(
        "revision_stage_approve_click",
        this.getRevisionParentStage(this.revision, this.workflow)
      );
    },
  },
};
</script>

<style scoped>
.btn-primary {
  background-color: #5995b7;
  border-color: #5995b7;
}
.btn-warning {
  background-color: #e57671;
  border-color: #e57671;
  color: white;
}
</style>
