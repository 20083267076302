const { DateTime } = require("luxon-business-days");
export default {
  // data() {
  //   return {
  //   }
  // },
  methods: {
    /**
     * Finds the Stage containing the given Revision
     * @param {object} revision
     * @param {object} workflow
     * @returns A Stage object or null
     */
    getRevisionParentStage(revision, workflow) {
      return workflow.tasks.find(
        (stage) => this.getRevisionIndexInStage(revision, stage) > -1
      );
    },
    getRevisionIndexInStage(revision, stage) {
      return stage.tasks.findIndex((rev) => rev.id === revision.id);
    },
    /** Checks if the given revision is the First Preview one for the given stage */
    isFirstPreviewInStage(revision, stage) {
      if (stage.tasks.length < 1) return false;
      return stage.tasks[0].id === revision.id;
    },
    /** Checks if the current revision is the First Revision (not a preview) */
    isFirstRevisionInStageExcludingPreviews(revision, stage) {
      if (stage.tasks.length < 2) return false;
      return stage.tasks[1].id === revision.id;
    },
    /** Checks if the current revision is the Last Revision in the stage */
    isLastRevisionInStage(revision, stage) {
      if (stage.tasks.length < 2) return false;
      const revCnt = stage.tasks.length;
      return stage.tasks[revCnt - 1].id === revision.id;
    },
    getPreviousRevision(revision, stageOrWorkflow) {
      let stage;
      if (stageOrWorkflow.type === "Stage") {
        stage = stageOrWorkflow; // stageOrWorkflow is a Stage
      } else {
        stage = this.getRevisionParentStage(revision, stageOrWorkflow); // stageOrWorkflow is a Workflow
      }
      let index = this.getRevisionIndexInStage(revision, stage);
      if (index == 0) return null;
      return stage.tasks[index - 1];
    },

    /**
     * Gets the number of agreed revisions (excluding the preview) for this stage
     * @returns number of non-additional revs
     */
    getAgreedRevisionsInStage(stage) {
      if (!stage.started_on) return stage.revisionsWithoutPreview.length; // If not even started, then all are agreed

      let agreedRevs = 0;

      // Determine initial number of revs and those requested additionally
      for (let r = 0; r < stage.revisionsWithoutPreview.length; r++) {
        if (stage.revisionsWithoutPreview[r].requested_on) {
          break;
        } else {
          agreedRevs++;
        }
      }

      return agreedRevs;
    },
    /**
     * Gets the number of revisions (excluding the preview) used until the stage was approved.
     * Counts only the completed ones
     * @returns number
     */
    getExecutedRevisionsInStage(stage) {
      if (!stage.started_on) return 0; // Not even started

      let executedRevs = 0;

      // Determine initial number of revs and those requested additionally
      for (let r = 0; r < stage.revisionsWithoutPreview.length; r++) {
        if (stage.revisionsWithoutPreview[r].completed_on) {
          executedRevs++;
        } else {
          break;
        }
      }

      return executedRevs;
    },
    /**
     * Find if any revision is 'in_progress' or awaiting_feedback
     * If none, find the first non-completed revision
     *  @param {object} workflow Workflow composed of an array of Stages
     *  @returns A revision object, for the first active revision, or undefined if all stages are complete.
     */
    getCurrentRevision(workflow) {
      let stage = this.getCurrentStage(workflow);
      if (!stage) return null;
      return stage.tasks?.find((revision) =>
        !revision.completed_on ? revision : null
      );
    },

    getTotalNumberOfRevisionsIncludingPreview(workflow) {
      if (!workflow?.tasks) return 0;
      return workflow.tasks.reduce(function (accumulator, curTask) {
        return accumulator + (curTask?.tasks?.length || 1); // We treat and empty stage as at least having 1 revision
      }, 0);
    },

    /**
     * Gets the number of completed Revisions.
     * A Complete and Approved revision is worth 1. If complete but not yet approved 0.5
     */
    getCompletedRevisionsIncludingPreview(workflow) {
      if (!workflow?.tasks) return 0;
      return workflow.tasks.reduce(function (accumulator, curTask) {
        if (curTask.status === "completed") {
          return accumulator + (curTask?.tasks.length || 1); // We treat and empty stage as at least having 1 revision
        } else {
          if (curTask.tasks) {
            if (curTask.tasks.length === 0 && curTask.started_on) {
              // Empty stage - Add half a point since it is not yet completed
              accumulator += 0.5;
            } else {
              curTask.tasks.forEach((rev) => {
                if (rev.completed_on) accumulator++;
                else if (rev.sent_on) accumulator += 0.5;
              });
            }
          }
          return accumulator;
        }
      }, 0);
    },

    /**
     *
     * @param workflow
     * @param is_survey_sent
     * @param send_csat_survey
     * @param survey_completed
     * @return {number}
     */
    getProductionProgress(
      workflow,
      is_survey_sent,
      send_csat_survey,
      survey_completed = 0
    ) {
      // TODO : Revise this calculation
      const totalPercentage = Math.round(
        100 *
          (this.getCompletedRevisionsIncludingPreview(workflow) /
            this.getTotalNumberOfRevisionsIncludingPreview(workflow))
      );
      if (totalPercentage === 100) {
        if (!this.$auth.user.isAgent) {
          if (is_survey_sent && !survey_completed) {
            return totalPercentage - 1;
          }
        } else {
          if (!is_survey_sent && send_csat_survey) {
            return totalPercentage - 1;
          }
        }
      }
      return totalPercentage;
    },

    /**
     * Find if any stage is 'in_progress'
     * If none, find the first stage where status = pending or empty
     *  @param {object} workflow Workflow composed of an array of Stages
     *  @returns A stage object, for the first in_progress stage, or undefined if all stages are complete.
     */
    getCurrentStage(workflow) {
      if (!workflow) return null;
      let stages = workflow.tasks;
      if (!stages || stages.length === 0) {
        return null;
      }
      let stage = stages.find((stage) => stage.status == "in_progress");
      if (!stage) {
        stage = stages.find(
          (stage) => !stage.status || stage.status == "pending"
        );
      }
      return stage;
    },

    /**
     * Check if the given @param stage is found as the last stage in @param workflow
     * @param {object} stage Stage we want to check
     * @param {object} workflow Workflow we want to check
     * @returns true or false
     */
    isLastStage(stage, workflow) {
      if (!workflow || !stage) return null;
      let stages = workflow.tasks;
      if (!stages || stages.length === 0) {
        return false;
      }
      return stages[stages.length - 1].id === stage.id;
    },

    getWorkflowTemplate_Empty() {
      return {
        tasks: [],
      };
    },

    getWorkflowFromTemplate(template) {
      let wf = { tasks: [] };
      template.stages.forEach((stage) => {
        stage.revisions = Number(stage.revisions);
        let task = {
          type: "Stage",
          title: stage.title,
          data: {
            qcChecklistId: stage.data ? Number(stage.data.qcChecklistId) : null,
          },
          tasks: [],
          is_deliverable: stage.is_deliverable,
        };
        for (let i = 0; i <= stage.revisions; i++) {
          if (stage.revisions !== 0) {
            task.tasks.push({ type: "Revision" });
          }
        }
        wf.tasks.push(task);
      });

      return wf;
    },
    cellValue_ProjectStatus(text) {
      switch (text) {
        case "Overdue Feedback":
          return "2";
        case "Awaiting Feedback":
          return "3";
        case "In production":
          return "4";
        case "Completed":
          return "5";
        case "Cancelled":
          return "6";
      }

      return "1";
    },
    cellText_ProjectStatus: function (project) {
      if (!project.workflow) return "";
      if (project.cancelled) return "Cancelled";
      const daysForFeedback = project.days_allowed_for_client_feedback;

      const revision = this.getCurrentRevision(project.workflow);
      if (!revision) {
        // In case of a stage without revisions, this might not actually be 'completed'
        const stage = this.getCurrentStage(project.workflow);
        if (!stage || stage.status === "completed") {
          if (!this.$auth.user.isAgent) {
            if (project.is_survey_sent && !project.survey_completed) {
              return "Pending CSAT";
            } else {
              return "Completed";
            }
          } else {
            if (project.is_survey_sent || !project.send_csat_survey) {
              return "Completed";
            } else {
              return "Pending CSAT";
            }
          }
        } else {
          return "In production";
        }
      }
      if (revision.status === "awaiting_feedback") {
        let until = DateTime.fromISO(revision.sent_on);
        if (!until.isValid) {
          return "Awaiting Feedback";
        }
        until = until.plusBusiness({ days: daysForFeedback }).toJSDate();
        if (new Date() > until) return "Overdue Feedback";
        return "Awaiting Feedback";
      }
      if (revision.status === "overdue_feedback") {
        return "Overdue Feedback";
      }
      return "In production";
    },
    cellClass_ProjectStatus: function (project) {
      if (project?.cancelled) return "badge xs badge-disabled";
      const revision = this.getCurrentRevision(project.workflow);
      if (!revision) {
        // In case of a stage without revisions, this might not actually be 'completed'
        const stage = this.getCurrentStage(project.workflow);
        if (!stage || stage.status === "completed") {
          if (!this.$auth.user.isAgent) {
            if (project.is_survey_sent && !project.survey_completed) {
              return "badge xs badge-primary";
            } else {
              return "badge xs badge-success";
            }
          } else {
            if (project.is_survey_sent || !project.send_csat_survey) {
              return "badge xs badge-success";
            } else {
              return "badge xs badge-primary";
            }
          }
        } else {
          return "badge xs badge-info";
        }
      }
      if (revision.status === "awaiting_feedback") {
        return "badge xs badge-primary";
      }
      return "badge xs badge-info";
    },
    progressColor(project) {
      const revision = this.getCurrentRevision(project.workflow);
      if (!revision) {
        // In case of a stage without revisions, this might not actually be 'completed'
        const stage = this.getCurrentStage(project.workflow);
        if (!stage || stage.status === "completed") {
          if (!this.$auth.user.isAgent) {
            if (project.is_survey_sent && !project.survey_completed) {
              return "warning";
            } else {
              return "success";
            }
          } else {
            if (project.is_survey_sent || !project.send_csat_survey) {
              return "success";
            } else {
              return "warning";
            }
          }
        } else {
          return "";
        }
      }
      if (revision.status === "awaiting_feedback") {
        return "warning";
      }
      return "";
    },
    getProjectCompletedStatusWithApprovalDate(workflow) {
      const tasks = workflow?.tasks || [];
      // Check if all tasks are completed
      const allCompleted =
        tasks.length > 0
          ? tasks.every((task) => task.status === "completed")
          : false;

      // Get approved dates of completed tasks
      const completedTasks = tasks
        .filter((task) => task.status === "completed")
        .map((task) => new Date(task.approved_on).getTime() || 0);

      // Find the latest approved date among completed tasks
      const latestApprovedDate =
        completedTasks.length > 0
          ? new Date(Math.max(...completedTasks))
          : null;

      return {
        allTasksCompleted: allCompleted,
        latestApprovedDate: latestApprovedDate,
      };
    },
    makeTrackableLink(revisionId, tenantDomain) {
      // Filter the array to get objects with the given revisionId
      const filteredEvents = this.project?.sendPreviewEvents.filter(
        (event) => event.revisionId === revisionId
      );

      // Check if there are objects with the given revisionId
      if (filteredEvents.length > 0) {
        // Use reduce to find the object with the latest event_time
        const latestEvent = filteredEvents.reduce((prev, current) => {
          return new Date(current.event_time) > new Date(prev.event_time)
            ? current
            : prev;
        });

        // Create and return the link using the trackLinkId and tenantDomain
        return `https://${tenantDomain}/link/${latestEvent.trackLinkId}`;
      } else {
        return null; // Return null if no matching object is found
      }
    },
  },
};
