<template>
  <div>
    <div class="input-group-prepend float-right">
      <button
        id="ddlMergeTags"
        class="btn btn-outline-secondary dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Choose tag to insert
      </button>
      <div class="dropdown-menu">
        <a
          v-for="mergeTag in mergeTags"
          :key="mergeTag.name"
          class="dropdown-item"
          @click="insertMergeTag(mergeTag)"
          >{{ $t(`mergeTags.${mergeTag.key}`, language) }}</a
        >
      </div>
    </div>
    <label class="float-right mt-2 mr-2" for="ddlMergeTags">Merge Tags</label>
  </div>
</template>

<script>
export default {
  name: "MergeTagsDropdown",
  props: {
    emailTemplateFilter: {
      type: String,
      default: "",
    },
    language: {
      type: String,
    },
  },
  data() {
    return {
      allMergeTags: [
        {
          value: "[client name]",
          name: "Client's business name",
          key: "client_name",
        },
        { value: "[project name]", name: "Project name", key: "project_name" },
        {
          value: "[contact name]",
          name: "Project Contact's first name (or 'everyone' if more than one)",
          key: "contact_name",
        },
        {
          value: "[recipient name]",
          name: "Contact's full name",
          key: "recipient_name",
        },
        {
          value: "[recipient fname]",
          name: "Contact's first name",
          key: "recipient_fname",
        },
        {
          value: "[recipient lname]",
          name: "Contact's last name",
          key: "recipient_lname",
        },
        {
          value: "[recipient email]",
          name: "Contact's email",
          key: "recipient_email",
        },
        {
          value: "[user name]",
          name: "Your name and surname",
          key: "user_name",
        },
        {
          value: "[user email]",
          name: "Your email address",
          key: "user_email",
        },
        {
          value: "[stage name]",
          name: "Current stage name",
          key: "stage_name",
        },
        {
          value: "[revision number]",
          name: "Position of revision in stage, in words. Ex: One, Two, etc..",
          key: "revision_number",
        },
        {
          value: "[revision number #]",
          name: "Position of revision in stage, in numbers; 1, 2, 3",
          key: "revision_number_n",
        },
        {
          value: "[revision number st]",
          name: "Position of revision in stage, in words; First, Second, Third, etc..",
          key: "revision_number_st",
        },
        {
          value: "[stage revisions left]",
          name: "Revisions left in stage, in words. Ex: One, Two, etc..",
          key: "stage_revisions_left",
        },
        {
          value: "[stage revisions left #]",
          name: "Revisions left in stage, in numbers; 1, 2, 3",
          key: "stage_revisions_left_n",
        },
        {
          value: "[stage revisions count]",
          name: "Total revisions in current stage, in words. Ex: One, Two, etc..",
          key: "stage_revisions_count",
        },
        {
          value: "[allowed feedback days]",
          name: "Business days allowed for feedback",
          key: "allowed_feedback_days",
        },
        {
          value: "[timeline link]",
          name: "Link to project timeline",
          key: "timeline_link",
        },
        { value: "[review link]", name: "Link to review", key: "review_link" },
        {
          value: "[deliverable name]",
          name: "Deliverable name",
          key: "deliverable_name",
        },
        {
          value: "[deliverable link]",
          name: "Deliverable link",
          key: "deliverable_link",
        },
        {
          value: "[deliverable notes]",
          name: "Deliverable additonal notes",
          key: "deliverable_notes",
        },
        { value: "[survey link]", name: "Survey link", key: "survey_link" },
        {
          value: "[email signature]",
          name: "Email signature",
          key: "email_signature",
        },
        {
          value: "[workspace name]",
          name: "Workspace name",
          key: "workspace_name",
        },
        {
          value: "[workspace url]",
          name: "Workspace URL",
          key: "workspace_url",
        },
        {
          value: "[business days overdue]",
          name: "Total business days overdue",
          key: "business_days_overdue",
        },
      ],
    };
  },
  computed: {
    mergeTags() {
      let subset = [...this.allMergeTags];
      if (
        this.emailTemplateFilter.indexOf("_rev") > 0 ||
        this.emailTemplateFilter === "invite" ||
        this.emailTemplateFilter === "survey" ||
        this.emailTemplateFilter === "reminder"
      ) {
        // Remove Deliverable specific tags
        subset = subset.filter((tag) => !tag.value.startsWith("[deliverable "));
        subset = subset.filter((tag) => !tag.value.startsWith("[survey "));
      }
      if (
        this.emailTemplateFilter === "deliverable" ||
        this.emailTemplateFilter === "survey" ||
        this.emailTemplateFilter === "invite"
      ) {
        // Remove Stage & Revision specific tags
        subset = subset.filter((tag) => !tag.value.startsWith("[stage "));
        subset = subset.filter((tag) => !tag.value.startsWith("[revision "));
        subset = subset.filter((tag) => !tag.value.startsWith("[review "));
        subset = subset.filter((tag) => !tag.value.startsWith("[survey "));
      }
      if (this.emailTemplateFilter === "invite") {
        // Remove project specific tags
        subset = subset.filter((tag) => !tag.value.startsWith("[contact "));
        subset = subset.filter((tag) => !tag.value.startsWith("[project "));
        subset = subset.filter(
          (tag) => !tag.value.startsWith("[allowed feedback days]")
        );
        subset = subset.filter((tag) => !tag.value.startsWith("[timeline "));
        subset = subset.filter((tag) => !tag.value.startsWith("[survey "));
      }
      if (this.emailTemplateFilter === "survey") {
        // Remove project specific tags
        subset = subset.filter((tag) => !tag.value.startsWith("[agency "));
        subset = subset.filter((tag) => !tag.value.startsWith("[contact "));
        subset = subset.filter(
          (tag) => !tag.value.startsWith("[recipient name]")
        );
      }
      if (
        this.emailTemplateFilter !== "invite" &&
        this.emailTemplateFilter !== "survey"
      ) {
        subset = subset.filter((tag) => !tag.value.startsWith("[recipient ")); // This is specific to when we invite a Contact
      }
      if (this.emailTemplateFilter !== "survey") {
        subset = subset.filter((tag) => !tag.value.startsWith("[survey "));
      }
      if (this.emailTemplateFilter === "feedback_auto_reminder") {
        subset = subset.filter((tag) => !tag.value.startsWith("[survey "));
        subset = subset.filter((tag) => !tag.value.startsWith("[recipient "));
        subset = subset.filter((tag) => !tag.value.startsWith("[user "));
        subset = subset.filter((tag) => !tag.value.startsWith("[revision "));
        subset = subset.filter((tag) => !tag.value.startsWith("[deliverable "));
        subset = subset.filter((tag) => !tag.value.startsWith("[email "));
      }

      return subset;
    },
  },
  methods: {
    insertMergeTag(mergeTag) {
      this.$emit("change", mergeTag);
    },
  },
};
</script>
