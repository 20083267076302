<template>
  <li class="timeline-item">
    <div v-if="preTitle" class="timeline-info">
      <span>{{ preTitle }}</span>
    </div>
    <div :class="markerClasses">
      <!-- if stage is deliverable stage and is in progress, then show the Flag icon -->
      <i v-if="displayFlagIcon" class="fa fa-flag fa-lg text-cyan"></i>
      <div v-if="displayCheckIcon" class="icon text-cyan text-large">
        <i class="fa fa-check-circle"></i>
      </div>
    </div>
    <div class="timeline-content">
      <h3 class="timeline-title">
        <div>
          {{ model.title }}
          <stage-menu
            @clicked="
              (item) => {
                onMenuClick(item, model);
              }
            "
            :items="
              makeMenu([
                !model.status &&
                  !root.is_linear &&
                  model.type === 'Stage' &&
                  isAgency &&
                  menuItems.StartStage,
                !model?.revisionsWithoutPreview?.length &&
                  model.type === 'Stage' &&
                  model.status === 'in_progress' &&
                  isAgency &&
                  menuItems.SendMessage,
                model.type === 'Stage' && isAgency && menuItems.AddNote,
                canUndoLastAction && menuItems.UndoLastAction,
                model.type === 'Revision' &&
                  model.status === 'in_progress' &&
                  isAgency &&
                  menuItems.ClientApproved,
              ])
            "
          />
          <span v-if="model.type === 'Stage' && isNotesAvailable">
            <div class="d-inline-block pl-1">
              <a
                href="#"
                data-toggle="modal"
                @click="onNotesIconClicked(model)"
                :style="{ color: '#bfbfbf' }"
              >
                <img
                  src="/assets/icons/note.svg"
                  height="20px"
                  width="20px"
                  style="margin-top: -1px"
                />
              </a>
            </div>
          </span>
          <span
            v-if="
              model.type !== 'Stage' &&
              model.status &&
              model.status !== 'rejected'
            "
            :class="cellClass_ProjectStatus(project)"
          >
            <span>{{ cellText_ProjectStatus(this.project) }}</span>
          </span>
        </div>
        <button
          v-if="isExpandable"
          class="btnExpand"
          @click="isExpanded = !isExpanded"
        >
          <i v-if="isExpanded" class="fa fa-angle-up"></i>
          <i v-else class="fa fa-angle-down"></i>
        </button>
      </h3>

      <h6
        v-if="this.model.type === 'Revision'"
        class="small text-black-50"
        style="position: relative"
      >
        <span v-html="subtitleText()" />
        <template v-if="isQCChecklistAvailable">
          <span>{{ getSubtitleWithQCCheckedBy(model) }} </span>
        </template>
        <template
          v-if="
            model?.data?.review_link &&
            !files?.length &&
            this.model.status !== 'awaiting_feedback'
          "
        >
          <span> | </span>
          <a
            class="shared-link text-black-50"
            :href="reviewLink(model)"
            target="_blank"
          >
            Shared link
          </a>
        </template>
      </h6>
      <date-time-picker
        v-if="$auth.user.isAgent"
        class="stage-date-change"
        type="datetime"
        :value="new Date(this.model.started_on)"
        input-class="form-date-control"
        format="YYYY-MM-DD HH:mm A"
        @input="(v) => onDateChange(v, 'started_on')"
        :input-attr="{
          id: `change-started-date-timeline-item-${model.id}`,
          ref: `change-started-date-timeline-item-${model.id}`,
          name: `change-started-date-timeline-item-${model.id}`,
        }"
        :disabled-date="
          (date) => {
            const today = new Date();
            return date > today;
          }
        "
      >
      </date-time-picker>
      <date-time-picker
        v-if="$auth.user.isAgent"
        class="stage-date-change"
        type="datetime"
        :value="new Date(this.model.completed_on)"
        input-class="form-date-control"
        format="YYYY-MM-DD HH:mm A"
        @input="(v) => onDateChange(v, `${this.model.status}_on`)"
        :input-attr="{
          id: `change-completed-date-timeline-item-${model.id}`,
          ref: `change-completed-date-timeline-item-${model.id}`,
          name: `change-completed-date-timeline-item-${model.id}`,
        }"
        :disabled-date="
          (date) => {
            if (this.model.status === 'rejected')
              return date < new Date(this.model.sent_on);

            const today = new Date();
            return date > today;
          }
        "
      >
      </date-time-picker>
      <date-time-picker
        v-if="$auth.user.isAgent"
        class="stage-date-change"
        type="datetime"
        :value="new Date(this.model.sent_on)"
        input-class="form-date-control"
        format="YYYY-MM-DD HH:mm A"
        @input="(v) => onDateChange(v, 'sent_on')"
        :input-attr="{
          id: `change-shared-date-timeline-item-${model.id}`,
          ref: `change-shared-date-timeline-item-${model.id}`,
          name: `change-shared-date-timeline-item-${model.id}`,
        }"
        :disabled-date="
          (date) => {
            const today = new Date();
            const started = new Date(this.model.started_on);
            return date < started || date > today;
          }
        "
      >
      </date-time-picker>
      <stage-subtitle-text
        v-if="this.model.type === 'Stage'"
        :stage="model"
        :workflow="root"
      />

      <revision-agency-timeline
        v-if="
          isAgency &&
          model.type === 'Revision' &&
          isOpen &&
          (model.status !== 'awaiting_feedback' ||
            model.id === revisionIdForReminder)
        "
        :shouldShowActionButtons="
          model.id === revisionIdForReminder ? false : true
        "
        :skipComposeButton="model.id === revisionIdForReminder"
        :project="project"
        :revision="model"
        :workflow="root"
        :files="files"
        @addedFiles="addedFiles"
        @removeFile="removeFile"
        :previousRevisionReviewLink="previousRevisionReviewLink"
        @revision_stage_approve_click="
          $emit('revision_stage_approve_click', $event)
        "
        @revision_send_preview_click="
          $emit('revision_send_preview_click', $event)
        "
      />
      <revision-client-timeline
        v-if="!isAgency && model.type === 'Revision' && isOpen"
        :project="this.project"
        :revision="model"
        :workflow="root"
        :files="files"
        @revision_send_message_click="
          $emit('revision_send_message_click', $event)
        "
        @revision_client_approve_click="
          $emit('revision_client_approve_click', $event)
        "
        @revision_client_reject_click="
          $emit('revision_client_reject_click', $event)
        "
      />
      <timeline-revision-agency-awaiting-feedback
        v-if="
          isAgency &&
          model.type === 'Revision' &&
          isOpen &&
          model.status === 'awaiting_feedback' &&
          model.id !== revisionIdForReminder
        "
        :project="this.project"
        :workflow="root"
        :revision="model"
        :files="files"
        @revision_reject_revision_click="
          $emit('revision_reject_revision_click', $event)
        "
        @revision_stage_approve_click="
          $emit('revision_stage_approve_click', $event)
        "
        @open_revision_for_reminder="revisionIdForReminder = $event"
        @removeFile="(i) => removeFile(i)"
      />
      <div v-if="!isOpen && isExpanded" class="col-lg-8 col-sm-12 pl-0 mt-4">
        <revision-files :files="files" :editable="false" />
      </div>
    </div>
    <SendMessageModal
      v-if="isSendMessageModalOpen"
      :model="model"
      :project="project"
      :workflow="root"
      @send="sendMessage"
      @close="isSendMessageModalOpen = false"
    >
    </SendMessageModal>
    <UndoLastActionConfirmModal
      v-if="isUndoLastActionConfirmModalOpen"
      @submit="undoLastAction"
      @close="isUndoLastActionConfirmModalOpen = false"
      :disableSubmitButton="isGoingBackStage"
    />
    <notes-model
      v-if="isShowNotesModel"
      @close="isShowNotesModel = false"
      :stageNotes="selectedStageNotes"
    ></notes-model>
    <NotesEditorModel
      v-if="isShowNotesEditorModel"
      title="Add note"
      :timeline="parent"
      action="add"
      :selectedStage="model"
      @close="isShowNotesEditorModel = false"
      @save="onSaveNote"
    />
    <slot></slot>
  </li>
</template>

<script>
import RevisionAgencyTimeline from "./RevisionAgencyTimeline.vue";
import RevisionClientTimeline from "./RevisionClientTimeline.vue";
import TimelineRevisionAgencyAwaitingFeedback from "./TimelineRevisionAgencyAwaitingFeedback.vue";
import timeMixin from "../../../mixins/time";
import workflowMixin from "../../../mixins/workflow";
import textEditorCompilerMixin from "../../../mixins/textEditorCompiler";
import StageSubtitleText from "./StageSubtitleText.vue";
import UndoLastActionConfirmModal from "@/components/ui/Modals/UndoLastActionConfirmModal.vue";
import {
  addRevisionFile,
  deleteRevisionFile,
  getRevisionFiles,
  startStageNonLinearTimelineAction,
  undoLastNonLinearTimelineAction,
  undoLastTimelineAction,
} from "@/apis/projects";
import SendMessageModal from "@/components/ui/Modals/SendMessageModal.vue";
import StageMenu, {
  makeMenu,
  menuItems,
} from "@/components/ui/Timeline/StageMenu.vue";
import NotesModel from "../Notes/NotesModel.vue";
import NotesEditorModel from "@/components/ui/Notes/NotesEditorModel.vue";
import { addNotes } from "@/apis/notes";
import notesFormatterMixin from "../../../mixins/notesFormatter";
import { updateRevisionDateTime } from "@/apis/workflows";
import eventBus, { channels } from "@/eventBus";
import {
  makeThumbnailFromImageFile,
  makeThumbnailFromVideoFile,
} from "@/utils/thumbnail";
import RevisionFiles from "@/components/ui/Timeline/RevisionFiles.vue";
import DateTimePicker from "@/components/ui/DateTimePicker.vue";

export default {
  components: {
    RevisionFiles,
    DateTimePicker,
    NotesEditorModel,
    StageMenu,
    UndoLastActionConfirmModal,
    RevisionAgencyTimeline,
    RevisionClientTimeline,
    TimelineRevisionAgencyAwaitingFeedback,
    StageSubtitleText,
    SendMessageModal,
    NotesModel,
  },
  name: "TimelineListItem",
  mixins: [
    timeMixin,
    workflowMixin,
    textEditorCompilerMixin,
    notesFormatterMixin,
  ],
  props: {
    preTitle: String,
    model: Object, // Task (Stage or Revision)
    parent: Object, // Stage or Workflow
    root: Object, // Main workflow
    isOpen: Boolean,
    project: Object,
    notes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isExpanded: false,
      revisionIdForReminder: "",
      emailTemplates: [],
      isUndoLastActionConfirmModalOpen: false,
      isSendMessageModalOpen: false,
      isGoingBackStage: false,
      menuItems,
      isShowNotesModel: false,
      selectedStageNotes: [],
      isShowNotesEditorModel: false,
      files: [],
      previousRevisionReviewLink: null,
    };
  },
  computed: {
    users() {
      return this.$store.getters.users.map((u) => ({
        id: u.id,
        name: (u.name + " " + (u.surname ?? "")).trim(),
      }));
    },
    isQCChecklistAvailable() {
      const stage = this.getRevisionParentStage(this.model, this.root);
      return stage?.data?.qcChecklistId;
    },
    isExpandable() {
      return !this.isOpen && this.files?.length > 0;
    },
    displayFlagIcon() {
      return this.model.is_deliverable && this.model.status;
    },
    displayCheckIcon() {
      return (
        !this.model.is_deliverable &&
        this.markerClass === "timeline-marker-completed"
      );
    },
    isLastStageAmongAll() {
      return this.isLastStage(this.model, this.root);
    },
    isNotesAvailable() {
      const avlNotes = this.notes.filter(
        (note) => note.stage_id === this.model?.id
      );
      return avlNotes.length;
    },
    isAgency() {
      return this.$auth.user.isAgent;
    },
    markerClass() {
      if (!this.model.status) {
        return "timeline-marker-pending";
      } else if (
        this.model.status === "in_progress" ||
        this.model.status === "awaiting_feedback"
      ) {
        return "timeline-marker in-progress";
      } else {
        //  if ((this.model.status === 'completed') || (this.model.status === 'rejected')) {
        return "timeline-marker-completed";
      }
    },
    markerClasses() {
      if (this.model.is_deliverable) {
        if (!this.model.status) {
          return "timeline-marker-pending";
        } else if (this.isLastStageAmongAll) {
          return "timeline-non-marker-pending";
        } else {
          return "timeline-marker-completed";
        }
      } else {
        return this.markerClass;
      }
    },
    canUndoLastAction() {
      if (this.root.tasks?.[0]?.id === this.model.id) {
        // it is the first stage from root, and therefore can't go back
        return false;
      }

      if (
        this.model.status === "in_progress" ||
        this.model.status === "awaiting_feedback"
      ) {
        if (!this.model?.tasks?.length && this.isAgency) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    actionByUser(userId, prefix = "by") {
      const user = this.users.find((u) => u.id === userId);
      if (user) return `${prefix} ${user.name}`;

      return "";
    },
    getName(eventData, prefix = "by") {
      return eventData ? `${prefix} ${eventData.user_name}` : "";
    },
    getSubtitleWithQCCheckedBy(revision) {
      const revisionIdToFind = revision.id;
      if (revision.status && revision.sent_on) {
        const latestEventObject = this.project.sendPreviewEvents
          ?.filter((item) => item.revisionId === revisionIdToFind) // Filter objects with the specified revisionId
          .reduce((latest, current) =>
            new Date(current.event_time) > new Date(latest.event_time)
              ? current
              : latest
          );
        return ` | ${this.actionByUser(latestEventObject.user_id, "QC'd by")}`;
      }
    },
    getSubtitleWithRejectedBy(revision) {
      const revisionIdToFind = revision.id;
      const filteredEvents = this.project.rejectEvents?.filter(
        (item) => item.revisionId === revisionIdToFind
      );
      const latestEventObject =
        filteredEvents?.length > 0
          ? filteredEvents.reduce((latest, current) =>
              new Date(current.event_time) > new Date(latest.event_time)
                ? current
                : latest
            )
          : null;
      return latestEventObject ? ` ${this.getName(latestEventObject)}` : "";
    },
    async addedFiles(files) {
      if (!files?.length) return;

      // filter out disallowed file extensions
      files = files.filter((file) => {
        if (
          !file.type ||
          file.type.match("x-sh") ||
          file.type.match("x-msdownload")
        ) {
          alert(
            file.type
              ? `${file.name ?? "file"} type ${file.type} is not allowed`
              : `${file.name ?? "file"} has no type`
          );

          return false;
        }

        return true;
      });

      // filter out existing files
      files = files.filter((file, i) => {
        const existingFileIndex = this.files.findIndex(
          (f) => f.name === file.name
        );
        if (existingFileIndex >= 0) {
          if (
            confirm(`${file.name} already exists. Do you want to overwrite?`)
          ) {
            files[i].toReplace = existingFileIndex;
            return true;
          }
          return false;
        }

        return true;
      });

      files.map(async (file) => {
        file.isUploading = true;
        let thumbnail;
        if (file.type.match("image")) {
          thumbnail = await makeThumbnailFromImageFile(file);
          file.thumbnail = URL.createObjectURL(thumbnail);
        } else if (
          file.type.match("video") &&
          file.type !== "video/quicktime"
        ) {
          thumbnail = await makeThumbnailFromVideoFile(file);
          file.thumbnail = URL.createObjectURL(thumbnail);
        }

        this.$nextTick(() => {
          addRevisionFile(
            this.project.id,
            this.model.id,
            file,
            false,
            (progress) => {
              const fileIndex = this.files.findLastIndex(
                (f) => f.name === file.name
              );
              if (fileIndex === -1) return;

              const revisionFiles = [...this.files];
              revisionFiles[fileIndex].progress =
                progress.loadedBytes / file.size;

              this.files = revisionFiles;
            }
          )
            .then(() => {
              const fileIndex = this.files.findLastIndex(
                (f) => f.name === file.name
              );
              if (fileIndex < 0) return;

              const revisionFiles = [...this.files];
              revisionFiles[fileIndex].isUploading = false;
              if (file.toReplace >= 0) revisionFiles.splice(file.toReplace, 1);

              this.files = revisionFiles;
            })
            .catch((error) => {
              this.notifyError(error, `Error uploading ${file.name ?? "file"}`);

              const fileIndex = this.files.findIndex(
                (f) => f.name === file.name
              );
              if (fileIndex < 0) return;

              const revisionFiles = [...this.files];
              revisionFiles.splice(fileIndex, 1);

              this.files = revisionFiles;
            });

          if (thumbnail) {
            addRevisionFile(this.project.id, this.model.id, thumbnail, true);
          }
        });
      });

      this.files = [...this.files, ...files];
    },
    removeFile(index) {
      const files = [...this.files];
      const filename = files[index]?.name;

      files.splice(index, 1);

      deleteRevisionFile(this.project.id, this.model.id, filename);

      this.files = files;
    },
    retrieveRevisionFiles() {
      getRevisionFiles(this.project.id, this.model.id)
        .then((res) => {
          this.files = res.map((f) => {
            const storageEndpoint = process.env.VUE_APP_AZ_STORAGE_ENDPOINT;
            const url = `${storageEndpoint}${
              storageEndpoint.at(-1) !== "/" ? "/" : ""
            }documents/${f.path}`;
            const filename = url.substring(url.lastIndexOf("/") + 1);

            let thumbnail = null;
            const type = `.${filename.split(".")[1]?.toLowerCase()}`;
            if (
              [
                ".jpg",
                ".png",
                ".svg",
                ".gif",
                ".jpeg",
                ".bmp",
                ".webm",
                ".ogg",
                ".mp4",
                ".avi",
                ".mkv",
                ".wmv",
                ".mov",
              ].includes(type)
            ) {
              thumbnail = url.replace(filename, `thumbnails/${filename}`);
            }

            return {
              url,
              size: f.size,
              created_on: f.created_on,
              thumbnail,
              name: filename,
              type,
            };
          });
        })
        .catch((error) => {
          this.notifyError(error, "Error getting files for revision");
        });
    },
    onDateChange(value, type) {
      let ldt = this.getLuxonDateTime(value, false, false);
      const newDate = ldt.toUTC().toISO();

      updateRevisionDateTime(
        this.root.id,
        this.parent.id,
        this.model.id,
        newDate,
        type
      )
        .then(() => {
          eventBus.$emit(channels.fetchTimeline);
        })
        .catch((error) => {
          this.notifyError(error);
        });
    },
    reviewLink(model) {
      const link = model?.data?.review_link;
      if (this.isAgency) {
        if (link && !link.includes("://")) return `https://${link}`;
        return link;
      } else {
        return this.makeTrackableLink(this.model.id, this.$auth.tenant.domain);
      }
    },
    makeMenu,
    onNotesIconClicked(modal) {
      this.selectedStageNotes = this.notes.filter(
        (note) => note.stage_id === modal.id
      );
      this.isShowNotesModel = true;
    },
    async onMenuClick(item, model) {
      if (item === menuItems.SendMessage) this.isSendMessageModalOpen = true;
      else if (item === menuItems.AddNote) {
        this.isShowNotesEditorModel = true;
      } else if (item === menuItems.UndoLastAction)
        this.isUndoLastActionConfirmModalOpen = true;
      else if (item === menuItems.StartStage) {
        startStageNonLinearTimelineAction(this.project.id, model.id).then(
          () => {
            eventBus.$emit(channels.refreshProject);
            this.isGoingBackStage = false;
          }
        );
      } else if (item === menuItems.ClientApproved) {
        if (this.isSubscriptionReadOnly()) return;

        this.$emit(
          "revision_client_approve_click",
          this.getRevisionParentStage(this.model, this.root)
        );
      }
    },
    timeElapsed(since, pastSuffix = " overdue") {
      return (
        this.timeElapsedBetween(since, new Date(), true, pastSuffix) + " ago"
      );
    },
    capitalize(text) {
      return text[0].toUpperCase() + text.substring(1);
    },
    subtitleText() {
      if (this.model.completed_on) {
        if (this.$auth.user.isAgent) {
          return `<label  for="change-completed-date-timeline-item-${
            this.model.id
          }" class="cursor-pointer select-none">
          ${this.capitalize(this.model.status)} ${this.timeElapsed(
            this.model.completed_on
          )}
          ${this.getSubtitleWithRejectedBy(this.model)}
        </label>`;
        } else {
          return `${this.capitalize(this.model.status)} ${this.timeElapsed(
            this.model.completed_on
          )}`;
        }
      }
      if (this.model.status === "in_progress" && this.project?.cancelled) {
        const cancelledDate = this.toWorkspaceDateTimeFormat(
          this.getLuxonDateTime(this.project?.cancelEventInfo?.event_time, true)
        );
        return (
          "Project cancelled by " +
          this.project?.cancelEventInfo?.cancelled_by +
          " on " +
          cancelledDate
        );
      }
      if (this.model.sent_on) {
        if (this.$auth.user.isAgent) {
          return `<label  for="change-shared-date-timeline-item-${
            this.model.id
          }" class="cursor-pointer select-none">
          Shared ${this.timeElapsed(this.model.sent_on)}
        </label>`;
        } else return `Shared ${this.timeElapsed(this.model.sent_on)}`;
      }
      if (this.model.started_on) {
        if (this.$auth.user.isAgent) {
          return `<label  for="change-started-date-timeline-item-${
            this.model.id
          }" class="cursor-pointer select-none">
Started ${this.timeElapsed(this.model.started_on, "")}
</label>`;
        } else return `Started ${this.timeElapsed(this.model.started_on, "")}`;
      }
      if (this.model.requested_on) {
        return "Requested " + this.timeElapsed(this.model.requested_on);
      }
    },
    sendMessage(sendMsgPayload) {
      this.$emit("stage_send_message_click", sendMsgPayload);
      this.isSendMessageModalOpen = false;
    },
    getStageId() {
      if (this.model && this.model.type === "Stage") {
        return this.model.id;
      } else if (this.model && this.model.type === "Revision") {
        return this.parent.id;
      }
    },
    async undoLastAction(reason) {
      this.isGoingBackStage = true;
      if (this.root.is_linear) {
        undoLastTimelineAction(this.project.id, reason)
          .then(() => {
            eventBus.$emit(channels.refreshProject);
            this.isUndoLastActionConfirmModalOpen = false;
            this.isGoingBackStage = false;
          })
          .catch(() => {
            this.isGoingBackStage = false;
          });
      } else {
        const stageId = this.getStageId();
        undoLastNonLinearTimelineAction(this.project.id, stageId, reason)
          .then(() => {
            eventBus.$emit(channels.refreshProject);
            this.isUndoLastActionConfirmModalOpen = false;
            this.isGoingBackStage = false;
          })
          .catch(() => {
            this.isGoingBackStage = false;
          });
      }
    },
    async onSaveNote({ payload, action }) {
      if (action === "add") {
        try {
          const newNote = await addNotes(this.project.id, payload);
          this.formatNotes(newNote, this.parent);
          this.$emit("add", newNote);
          this.notifySuccess("Note added");
        } catch (error) {
          this.notifyError(error, "Error adding note");
        }
      }
      this.isShowNotesEditorModel = false;
    },
  },
  async mounted() {
    if (
      this.$route.query?.action === "sendReminder" &&
      this.model.id === this.$route.query?.revisionId &&
      ["Awaiting Feedback", "Overdue Feedback"].includes(
        this.cellText_ProjectStatus(this.project)
      )
    ) {
      this.revisionIdForReminder = this.model.id;
    }

    if (this.model.type === "Revision") {
      this.retrieveRevisionFiles();

      // populate prev review link
      let review_link = this.model?.data?.review_link; // Maybe current revision has one already
      if (review_link) {
        this.previousRevisionReviewLink = review_link;
        return null;
      }

      // If not, fetch from previous one
      let prevRev = this.getPreviousRevision(this.model, this.root);
      if (!prevRev) return;

      const prevRevLink = prevRev?.data?.review_link;
      const prevRevFiles = await getRevisionFiles(
        this.project.id,
        prevRev.id
      ).then((res) => {
        return res;
      });

      if (prevRevFiles?.length) {
        this.previousRevisionReviewLink = null;
      } else if (prevRevLink) {
        this.previousRevisionReviewLink = prevRevLink;
      }
    }
  },
};
</script>

<style scoped>
.btnExpand {
  border: none;
  background-color: transparent;
  outline: none;
}

.btnExpand i {
  color: #c5c5c6;
  font-size: 1.5rem;
}

.timeline-title {
  max-width: 695px;
  display: flex;
  justify-content: space-between;
}
</style>
