var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.IsAllTasksCompleted)?_c('div',{staticClass:"col-6",staticStyle:{"padding-top":"20px","padding-bottom":"20px","border-top":"1px solid #e1e8ed"}},[(_vm.isCsatSurveyAvailable && !_vm.allSurveyCompleted)?_c('button',{staticClass:"btn btn-primary mr-2 position-relative",attrs:{"id":"send_csat_button","disabled":_vm.isSubscriptionReadOnly()},on:{"click":_vm.showFeedbackTab}},[_c('span',[_vm._v(" "+_vm._s(_vm.buttonLabel))])]):_vm._e(),_c('button',{staticClass:"btn btn-primary-custom mr-2",attrs:{"id":"send_deliverable_button","disabled":_vm.isSubscriptionReadOnly()},on:{"click":function($event){return _vm.goToDeliverablesTab()}}},[_vm._v(" Send Deliverables ")]),(
      _vm.isCsatSurveyAvailable &&
      !_vm.project.is_survey_sent &&
      _vm.project.send_csat_survey
    )?_c('h6',{staticClass:"small text-black-50"},[_c('i',{staticClass:"fa fa-exclamation-triangle pt-1",staticStyle:{"color":"orange"},attrs:{"id":"csat-pending-icon"}}),_c('span',[_vm._v(" Send CSAT to complete project")])]):_vm._e(),(_vm.isShowErrorForProjectComplete)?_c('ActionConfirmModal',{attrs:{"title":_vm.projectCompleteErrorModelProps.title,"text":_vm.projectCompleteErrorModelProps.text,"submitText":_vm.projectCompleteErrorModelProps.submitText,"submitClass":"btn-primary"},on:{"close":function($event){_vm.isShowErrorForProjectComplete = false},"submit":() => {
        _vm.showFeedbackTab();
        _vm.isShowErrorForProjectComplete = false;
      }}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }