<template>
  <div>
    <div>
      <button
        v-if="!openRevisionTimeLine"
        class="btn btn-info"
        type="button"
        @click="onComposeButtonClicked"
      >
        Compose update
      </button>
    </div>
    <div v-if="openRevisionTimeLine">
      <div class="col-12 pl-0">
        <div class="type-switch-group btn-group btn-group-sm mb-3">
          <button
            type="button"
            class="btn"
            :class="type === 'link' ? 'btn-primary' : 'btn-default'"
            :style="
              type === 'link' &&
              'background-color: #0069d9; border-color: #0062cc'
            "
            :disabled="files?.length > 0"
            @click="setType('link')"
          >
            <span :style="files?.length > 0 && 'opacity:.5;cursor:not-allowed'">
              Link
            </span>
          </button>
          <!-- `style` had to be used for the button's color because the bootstrap `btn-primary` class was overwritten in some components to be like the colors of `btn-info` -->
          <button
            type="button"
            class="btn"
            :class="type === 'files' ? 'btn-primary' : 'btn-default'"
            :style="
              type === 'files' &&
              'background-color: #0069d9; border-color: #0062cc'
            "
            @click="setType('files')"
          >
            Upload
          </button>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12 pl-0">
        <template v-if="type === 'link'">
          <label for="basic-url">Paste client review link here</label>
          <div class="input-group mb-3">
            <input
              ref="txtReviewLink"
              type="text"
              class="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
              v-model="reviewLink"
            />
          </div>
        </template>
        <div v-if="type === 'files'" class="form-group">
          <FileDrop
            :files="files"
            :field="{ name: `revision-${revision?.id}-files`, multiple: true }"
            @added="(files) => $emit('addedFiles', files)"
            @remove="(i) => $emit('removeFile', i)"
          />
        </div>
        <p>
          Time allowed for client feedback:
          <strong>{{ timeLeftForFeedback }}</strong>
        </p>
      </div>
      <div class="row clearfix">
        <div class="col-lg-8 col-sm-12 pl-0">
          <div class="input-group-prepend float-right" v-if="emailTemplate">
            <button
              id="ddlTemplates"
              class="btn btn-outline-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ emailTemplate.title }}
            </button>
            <div class="dropdown-menu">
              <a
                v-for="(template, index) in emailTemplates"
                :key="template.title"
                class="dropdown-item"
                @click="chosenTemplateIndex = index"
                >{{ template.title }}</a
              >
            </div>
          </div>
          <label class="float-right mt-2 mr-1" for="ddlTemplates"
            >Templates</label
          >
          <br /><br />
          <email-template-editor
            v-if="emailTemplate && emailTemplate.type"
            ref="txtRevisionMessage"
            v-model="emailText"
            :templateType="emailTemplate.type"
            :language="client.language"
            rows="10"
          />
          <br />
          <div class="d-flex justify-content-end">
            <div class="mr-4" style="margin-top: 0.5em">
              <toggle-button
                v-if="!isReminderTemplate"
                :initial-toggle-value="updateStatus"
                :label="'Move to next stage after sending'"
                @on-toggle="onToggleChange"
              />
            </div>
            <button
              class="btn btn-info btn-block w-25"
              type="button"
              @click="sendPreview()"
              :disabled="isSubscriptionReadOnly()"
            >
              Send
            </button>
          </div>
          <timeline-revision-agency-action-buttons
            v-if="shouldShowActionButtons"
            :revision="revision"
            :workflow="workflow"
            @open_revision_for_reminder="
              $emit('open_revision_for_reminder', $event)
            "
            @revision_reject_revision_click="
              $emit('revision_reject_revision_click', $event)
            "
            @revision_stage_approve_click="
              $emit('revision_stage_approve_click', $event)
            "
          />
        </div>
      </div>
    </div>
    <ReviewLinkMergeTagModal
      v-if="showReviewLinkMergeTagModal"
      @close="showReviewLinkMergeTagModal = false"
    />
    <QualityControlChecklistModal
      v-if="isQCChecked"
      :title="'test'"
      :qcChecklistTobeDisplayed="qcChecklistTobeDisplayed"
      @close="isQCChecked = false"
      @send="onQualityControlChecked()"
    />
  </div>
</template>

<script>
import TimelineRevisionAgencyActionButtons from "./RevisionAgencyActionButtons.vue";
import EmailTemplateEditor from "../EmailTemplates/EmailTemplateEditor.vue";
import workflowMixin from "../../../mixins/workflow";
import { getClientById } from "@/apis/clients";
import ToggleButton from "../ToggleButton.vue";
import textEditorCompilerMixin from "../../../mixins/textEditorCompiler";
import timeMixin from "../../../mixins/time";
import FileDrop from "@/components/FileDrop.vue";
import ReviewLinkMergeTagModal from "@/components/ui/Modals/ReviewLinkMergeTagModal.vue";
import QualityControlChecklistModal from "@/components/ui/Modals/QualityControlChecklistModal.vue";

const { DateTime } = require("luxon-business-days");

export default {
  name: "RevisionAgencyTimeline",
  components: {
    ReviewLinkMergeTagModal,
    FileDrop,
    TimelineRevisionAgencyActionButtons,
    EmailTemplateEditor,
    ToggleButton,
    QualityControlChecklistModal,
  },
  mixins: [workflowMixin, textEditorCompilerMixin, timeMixin],
  props: {
    revision: Object, // Task (Revision)
    workflow: Object, // Workflow (needed?)
    project: Object,
    previousRevisionReviewLink: String,
    shouldShowActionButtons: {
      type: Boolean,
      default: true,
    },
    skipComposeButton: {
      type: Boolean,
      default: false,
    },
    files: Array,
  },
  data() {
    return {
      type: "link",
      reviewLink: null,
      chosenTemplateIndex: 0,
      emailText: "",
      allEmailTemplates: [],
      emailTemplates: [],
      client: {},
      updateStatus: true,
      openRevisionTimeLine: this.skipComposeButton,
      showReviewLinkMergeTagModal: false,
      isQCChecked: false,
      qcChecklistTobeDisplayed: {},
    };
  },
  async mounted() {
    try {
      // Fetch client by project's client_id
      this.client = await getClientById(this.project.client_id);

      // Focus on review link when loading first preview
      this.focusOnReviewLinkWhenLoadingFirstPreview();
    } catch (error) {
      console.error("Error:", error);
    }
  },
  watch: {
    previousRevisionReviewLink: function () {
      if (!this.reviewLink) this.reviewLink = this.previousRevisionReviewLink;
    },
    files: function () {
      if (this.files?.length) this.type = "files";
    },
    chosenTemplateIndex: function () {
      this.emailText = this.replacePlaceholdersHelper(
        this.emailTemplateInClientLanguage.body,
        this.emailTemplateInClientLanguage.language
      );
    },
    client: function () {
      this.emailText = this.replacePlaceholdersHelper(
        this.emailTemplateInClientLanguage.body,
        this.emailTemplateInClientLanguage.language
      );
    },
    emailText: function () {
      this.emailText = this.replacePlaceholdersHelper(
        this.emailText,
        this.emailTemplateInClientLanguage.language
      );
    },
    "$store.getters.emailTemplates": {
      handler() {
        this.updateChosenTemplateIndex();
      },
      immediate: true, // Trigger handler immediately on component mount
    },
  },
  computed: {
    // gets the chosen email template in the client's language (falls back to the default language)
    emailTemplateInClientLanguage() {
      const clientLanguage = this.client?.language ?? "en";
      const chosenEmailTemplateId = this.emailTemplate.id;
      const templateVariants = this.allEmailTemplates.filter(
        (t) =>
          t.id === chosenEmailTemplateId ||
          t.parent_id === chosenEmailTemplateId
      );

      let template = templateVariants.find(
        (t) => t.language === clientLanguage
      );

      if (!template)
        // template in client language was not found, fall back on default language
        template = templateVariants.find((t) => t.language === "en");

      if (!template)
        // default to the parent template
        return this.emailTemplate;

      return template;
    },
    emailTemplate() {
      return this.emailTemplates[this.chosenTemplateIndex ?? 0];
    },
    isFirstPreview() {
      const stage = this.getRevisionParentStage(this.revision, this.workflow);
      return this.isFirstPreviewInStage(this.revision, stage);
    },
    isFirstRevision() {
      const stage = this.getRevisionParentStage(this.revision, this.workflow);
      return this.isFirstRevisionInStageExcludingPreviews(this.revision, stage);
    },
    isLastRevision() {
      const stage = this.getRevisionParentStage(this.revision, this.workflow);
      return this.isLastRevisionInStage(this.revision, stage);
    },
    isAdditionalRevision() {
      return this.revision.requested_on !== undefined;
    },
    timeLeftForFeedback() {
      const daysForFeedback = this.project.days_allowed_for_client_feedback;
      let until = DateTime.fromISO(this.revision.sent_on);
      if (!until.isValid) {
        return daysForFeedback + " business days";
      }
      until = until.plusBusiness({ days: daysForFeedback }).toJSDate();

      return this.timeElapsedBetween(new Date(), until);
    },
    isReminderTemplate() {
      return this.emailTemplates[this.chosenTemplateIndex]?.type === "reminder";
    },
    qcChecklistTemplates() {
      return this.$store.getters.qcChecklistTemplates;
    },
  },
  methods: {
    setType(newType) {
      this.type = newType;
    },
    updateChosenTemplateIndex() {
      // Filter and sort email templates
      this.allEmailTemplates = (this.$store.getters.emailTemplates || [])
        .filter(
          (template) => template.type !== "invite" && template.type !== "survey"
        )
        .sort((a, b) => a.id - b.id);
      this.emailTemplates = this.allEmailTemplates.filter((t) => !t.parent_id);

      let templateType = this.getTemplateTypeForCurrentState();
      let index = this.emailTemplates.findIndex(
        (et) => et.type === templateType
      );
      if (index === -1) {
        if (templateType === "additional_rev_free") {
          index = this.emailTemplates.findIndex(
            (et) => et.type === "additional_rev_cost"
          );
        } else if (templateType === "additional_rev_cost") {
          index = this.emailTemplates.findIndex(
            (et) => et.type === "additional_rev_free"
          );
        }
        if (templateType.includes("_rev")) {
          index = this.emailTemplates.findIndex(
            (et) => et.type === "other_rev"
          );
        }
      }
      this.chosenTemplateIndex = Math.max(0, index);
      // Set email text based on chosen template
      if (this.chosenTemplateIndex !== -1) {
        this.emailText = this.replacePlaceholdersHelper(
          this.emailTemplateInClientLanguage.body,
          this.emailTemplateInClientLanguage.language
        );
      }
    },
    onComposeButtonClicked() {
      const stage = this.getRevisionParentStage(this.revision, this.workflow);
      const qcChecklistId = stage.data
        ? Number(stage.data.qcChecklistId)
        : undefined;
      const qcTobeDisplayed = this.qcChecklistTemplates.find(
        (template) => template.id === Number(qcChecklistId)
      );
      if (qcTobeDisplayed) {
        this.qcChecklistTobeDisplayed = qcTobeDisplayed;
        this.isQCChecked = true;
      } else {
        this.openRevisionTimeLine = true;
      }
    },
    onQualityControlChecked() {
      this.openRevisionTimeLine = true;
      this.isQCChecked = false;
      // Perform any additional actions you want after sending
    },
    getTemplateTypeForCurrentState() {
      let templateType = "other_rev";
      if (this.isFirstPreview) templateType = "first_preview"; // If first preview
      if (this.isFirstRevision) templateType = "first_rev"; // If first revision
      if (this.isAdditionalRevision) templateType = "additional_rev_free";
      if (this.isLastRevision) templateType = "last_rev";
      if (this.revision.status === "awaiting_feedback")
        templateType = "reminder";

      return templateType;
    },
    focusOnReviewLinkWhenLoadingFirstPreview() {
      if (this.getTemplateTypeForCurrentState() === "first_preview") {
        setTimeout(() => {
          this.$refs.txtReviewLink?.focus();
        }, 200); // Wait for Summernote to apply changes
      } else {
        this.$refs.txtRevisionMessage?.focus();
      }
    },
    sendPreview() {
      if (this.files?.length || !this.reviewLink) this.reviewLink = "";

      const reviewLinkMergeTagRegex = /(http(s)?:\/\/)?\[review( |%20)link\]/g;
      const processedEmailText = this.emailText?.replace(
        reviewLinkMergeTagRegex,
        "[review link]"
      );

      if (
        (this.files?.length || this.reviewLink?.trim()?.length) &&
        !processedEmailText?.includes(`[review link]`)
      ) {
        this.showReviewLinkMergeTagModal = true;
        return;
      }

      const reviewLink = this.files?.length ? "[files]" : this.reviewLink;

      if (!this.files?.length && reviewLink === "[files]") {
        this.notifyError("You did not upload any files");
        return;
      }

      const urlRegex = /^(http|https|ftp):\/\/.*$/;
      if (
        reviewLink?.length &&
        reviewLink !== "[files]" &&
        !reviewLink.match(urlRegex)
      ) {
        this.notifyError("Review link must be a URL");
        return;
      }

      let event = {
        event_id: this.revision.id,
        reviewLink: reviewLink,
        emailText: processedEmailText,
        updateStatus: this.updateStatus,
      };
      if (this.emailTemplates[this.chosenTemplateIndex].type === "reminder") {
        event.isReminder = true;
        event.updateStatus = false;
      }
      this.$emit("revision_send_preview_click", event);
    },
    onToggleChange(value) {
      this.updateStatus = value;
    },
    replacePlaceholdersHelper(text, language = "en") {
      return this.replacePlaceholders(
        text,
        this.workflow,
        this.client,
        this.revision,
        this.project,
        this.reviewLink,
        false,
        language
      );
    },
  },
};
</script>

<style scoped>
.type-switch-group button {
  padding: 6px 18px;
}

.type-switch-group button:first-child {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.type-switch-group button:last-child {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
</style>
