const config = require("./config.json");
const httpCall = require("./common").default;

const workflowsUrl = config.apis.workflows.url + "workflows/";

/**
 * Adds some helper properties to make it easier to work with a Workflow object
 * See also @function stripExtraPropertiesFromWorkflow
 * @param {object} workflow
 * @returns the same object, modified
 */
function decorateWorkflow(workflow) {
  workflow.tasks.forEach((stage) => {
    if (stage.tasks.length > 1) {
      stage.revisionsWithoutPreview = stage.tasks.slice(1);
    } else {
      stage.revisionsWithoutPreview = [];
    }
  });

  return workflow;
}

/**
 * Removes the 'helper' properties `revisionsWithoutPreview` added in `decorateWorkflow`
 * @param {object} workflow
 */
function stripExtraPropertiesFromWorkflow(workflow) {
  workflow.tasks.forEach((stage) => {
    delete stage.revisionsWithoutPreview;
  });

  return workflow;
}

exports.getWorkflowById = async (id) => {
  const wf = await httpCall.get(`${workflowsUrl}${id}`);

  return decorateWorkflow(wf);
};

exports.getAllWorkflows = async (filter) => {
  const wfs = await httpCall.get(
    workflowsUrl + (filter ? "?query=" + filter : "")
  );
  wfs.forEach((wf) => {
    decorateWorkflow(wf);
  });

  return wfs;
};

exports.addWorkflow = async (workflow) => {
  workflow = stripExtraPropertiesFromWorkflow(workflow);
  return await httpCall.create(workflowsUrl, workflow);
};

/**
 * Update the given workflow object.
 * An id must be provided and @param workflow.id takes precedence over @param workflowId
 * @param {object} workflow The object to update. The id can be omitted if specified in @param workflowId, but is required otherwise
 * @param {number} workflowId Optional. Needed only if @param workflow does not have an id property
 * @returns
 */
exports.updateWorkflow = async (workflow, workflowId) => {
  if (!workflow.id || workflow.id < 1) {
    if (!workflowId || workflowId < 1) {
      throw new Error("No valid workflow ID provided");
    }
    workflow.id = workflowId;
  }
  workflow = stripExtraPropertiesFromWorkflow(workflow);
  return await httpCall.update(workflowsUrl + workflow.id, workflow);
};

exports.deleteWorkflow = async (workflow) => {
  return this.deleteWorkflowById(workflow.id);
};

exports.deleteWorkflowById = async (id) => {
  return await httpCall.remove(`${workflowsUrl}${id}`);
};

exports.appendRevision = async (workflow, stage) => {
  return await httpCall.create(
    workflowsUrl + workflow.id + "/stages/" + stage.id + "/revisions"
  );
};

exports.approveStage = async (workflow, stage) => {
  return await httpCall.update(
    workflowsUrl + workflow.id + "/stages/" + stage.id + "/approve"
  );
};

exports.rejectStage = async (workflow, stage) => {
  return await httpCall.update(
    workflowsUrl + workflow.id + "/stages/" + stage.id + "/reject"
  );
};
// [DEPRECATED] This API call is deprecated. Use approveStage() instead.
exports.approveRevision = async (workflow, stage, revision) => {
  return await httpCall.update(
    workflowsUrl +
      workflow.id +
      "/stages/" +
      stage.id +
      "/revisions/" +
      revision.id +
      "/approve"
  );
};

exports.rejectRevision = async (workflow, stage, revision) => {
  return await httpCall.update(
    workflowsUrl +
      workflow.id +
      "/stages/" +
      stage.id +
      "/revisions/" +
      revision.id +
      "/reject"
  );
};

exports.updateStageDateTime = async (workflowId, stageId, newDate, type) => {
  return await httpCall.update(
    workflowsUrl + workflowId + "/stages/" + stageId + `/${type}`,
    { [type]: newDate }
  );
};

/**
 * Change the date and time for the `type` attribute of a revision
 * @param {number} workflowId
 * @param {string} stageId
 * @param {string} revisionId
 * @param {string} newDate The new date and time to set
 * @param {string} type The name of the field to update ex. `started_on`, `sent_on`, etc
 * @returns The updated Workflow object
 */
exports.updateRevisionDateTime = async (
  workflowId,
  stageId,
  revisionId,
  newDate,
  type
) => {
  return await httpCall.update(
    workflowsUrl +
      workflowId +
      "/stages/" +
      stageId +
      "/revisions/" +
      revisionId +
      `/${type}`,
    {
      [type]: newDate,
    }
  );
};
